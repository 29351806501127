import {
  Box,
  Button,
  FormGroup,
  Grid,
  MenuItem,
  Select, SelectChangeEvent,
  TextField
} from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FetchAllSugars } from 'services/sugar.service';
import { SearchCategorySugarValues, searchOptionSugar } from 'types/entities/content';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import SugarDetailDialogV2 from './components/sugarDetailDialog';

const useSugars = () => {
  const [searchCategory, setSearchCategory] = useState<string>(SearchCategorySugarValues.phoneNumber);
  const [searchValue, setSearchValue] = useState<string>('');
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [open, setOpen] = useState<{ open: boolean; id: number }>({
    open: false,
    id: NaN
  });
  const columns: GridColDef[] = [
    {
      field: 'memberShipNumber',
      headerName: '회원번호',
      minWidth: 100,
    },
    {
      field: 'uniqueId',
      headerName: '고유 아이디',
      minWidth: 150,
    },
    {
      field: 'alias',
      headerName: '아이디',
      minWidth: 150,
    },
    {
      field: 'nickName',
      headerName: '닉네임',
      minWidth: 200,
    },
    {
      field: 'phoneNumber',
      headerName: '휴대폰 번호',
      minWidth: 160,
    },
    {
      field: 'currentSugarCount',
      headerName: '현재 설탕 수',
      minWidth: 160,
    },
    {
      field: 'accumulatedSugarCount',
      headerName: '누적 설탕 수',
      minWidth: 160,
    },
    {
      field: 'management',
      headerName: '',
      minWidth: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => {
            setOpen({
              open: true,
              id: params.row.id
            });
          }}
        >
          관리
        </Button>
      ),
    },
  ];

  const onClickMoveToV1 = (): void => {
    window.location.href = '/sugar-management';
  };

  const setupFilteredUsers = (users: any[]) => {
    setFilteredUsers(users.map((user: any) => ({
      id: user.userId,
      memberShipNumber: user.memberShipNumber,
      uniqueId: user.uniqueId,
      alias: user.alias,
      nickName: user.nickName,
      phoneNumber: user.phoneNumber,
      currentSugarCount: Number(user.currentSugarCount).toLocaleString('en'),
      accumulatedSugarCount: Number(user.accumulatedSugarCount).toLocaleString('en')
    })));
  }

  useEffect(() => {
    async function fetchAllSugars(): Promise<void> {
      const users = await FetchAllSugars();
      const formattedUsers = users.map((user: any) => ({
        userId: user.userId,
        id: user.userId,
        memberShipNumber: user.userId,
        uniqueId: user.uniqueId,
        alias: user.alias,
        nickName: user.nickName,
        phoneNumber: user.phone,
        currentSugarCount: user.currentSugar,
        accumulatedSugarCount: user.accumulatedSugar
      }))
      setAllUsers(formattedUsers);
      setupFilteredUsers(formattedUsers);
    }

    fetchAllSugars();
  }, []);

  const onClickSearchButton = useCallback((category: string, value: string) => {
    if (!value) {
      setupFilteredUsers(allUsers);
      return;
    }

    const filtered = allUsers.filter((user: any) => {
      const keyValue = user[category] ? user[category].toString() : '';

      return keyValue.includes(value.toString());
    });

    console.log(filtered);

    setupFilteredUsers(filtered);

  }, [allUsers]);

  return {
    models: {
      allUsers,
      searchCategory,
      searchValue,
      columns,
      filteredUsers,
      open,
    },
    operations: {
      setSearchCategory,
      setSearchValue,
      onClickMoveToV1,
      onClickSearchButton,
      setOpen,
    },
  }
}

export default function Sugars() {
  const { models, operations } = useSugars();

  return (
    <div className="main-content">

      <Grid container
            direction="row"
            alignItems="center">
        <Grid item>
          총 회원수: <b>{models.allUsers.length}</b>명 | 검색결과: <b>{models.filteredUsers.length}</b>명
        </Grid>
      </Grid>

      <Grid container
            mt={1}>
        <Grid item
              xs={12}>
          <FormGroup row>
            <Grid item
                  xs={2}>
              <Select fullWidth
                      value={models.searchCategory}
                      onChange={(e: SelectChangeEvent) => operations.setSearchCategory(e.target.value)}
                      displayEmpty>
                {searchOptionSugar && searchOptionSugar.length
                  ? searchOptionSugar.map((item) => (
                    <MenuItem
                      className="menu-item"
                      key={item.value}
                      value={item.value}
                    >
                      {item.label}
                    </MenuItem>
                  ))
                  : ''}
              </Select>
            </Grid>
            <Grid item
                  xs={8}>
              <TextField fullWidth
                         variant="outlined"
                         value={models.searchValue}
                         onChange={(e: ChangeEvent<HTMLInputElement>) => operations.setSearchValue(e.target.value)} />
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth
                      variant="contained"
                      onClick={() => operations.onClickSearchButton(models.searchCategory, models.searchValue)}
                      style={{
                        height: '100%'
                      }}>
                검색
              </Button>
            </Grid>
          </FormGroup>
        </Grid>
      </Grid>

      <Grid container
            mt={1}>
        <Grid item
              xs={12}>
          <Box width='100%'
               height='75vh'>
            <DataGrid columns={models.columns}
                      rows={models.filteredUsers}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 20 }
                        },
                      }}
                      pageSizeOptions={[5, 10]} />
          </Box>
        </Grid>
      </Grid>

      <SugarDetailDialogV2 open={models.open?.open}
                           id={models.open?.id}
                           handleClose={() => {
                             operations.setOpen({ open: false, id: NaN });
                             window.location.reload();
                           }}
                           handleRefresh={() => window.location.reload()} />
    </div>
  );
};
