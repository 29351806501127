import React from 'react';

import ModalRoot from './ModalRoot';

interface UserModalProps {
  open: boolean;
  onClose: () => void;
  userId: number;
}

export default function UserModal({
  open,
  onClose,
  userId,
}: UserModalProps) {
  const [user, setUser] = React.useState<undefined>(undefined);

  return (
    <ModalRoot open={open}
               onClose={onClose}
                modalName="user-modal">
      {userId}
    </ModalRoot>
  );
}
