import api, { CoreResponse } from '../api';
import { ReportedUser } from './types';

interface FetchUserReportedResponse extends CoreResponse {
  users: ReportedUser[];
}

export const FetchAllReportedUsers = async (): Promise<ReportedUser[]> => {
  try {
    const { data } = await api.get<FetchUserReportedResponse>('/api/admin/v2/users/all/reports?type=reported');

    return data.users;
  } catch {
    return [];
  }
}
