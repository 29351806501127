import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const DialogConfirmClose = ({
  open,
  handleClose,
  handleOk
}: {
  open: boolean;
  handleClose: () => void;
  handleOk: () => void;
}) => {
  const handleConfirm = () => {
    handleOk();
    handleClose();
  };

  const handleCloseDialog = (event: {}, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  return (
    <div>
      <Dialog
        className="dialog-confirm dialog-confirm-before-close"
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className="dialog-confirm-content"
            id="alert-dialog-description"
          >
            팝업창을 닫으시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="ok-button" onClick={handleConfirm} autoFocus>
            네
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogConfirmClose;
