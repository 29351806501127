import { Backdrop, CircularProgress } from '@mui/material';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';

const Loading = () => {
  const _isLoading = useSelector((state: RootState) => state.loading);

  return (
    <Backdrop
      sx={{ color: '#FF6151', zIndex: 9999 }}
      open={_isLoading.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
