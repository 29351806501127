import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Pagination,
  Select,
  TextField
} from '@mui/material';
import DialogResult from 'dialog/DialogResult';
import ButtonCustom from 'elements/Button';
import TabelComponent from 'elements/TableCell';
import { SetStateAction, useEffect, useState } from 'react';
import PmojiService from 'services/pmoji.service';
import { CheckBoxDefault } from 'types/checkbox.interface';
import { DialogResultProps, DialogState } from 'types/dialog.interface';
import { dialogResult } from 'types/entities/dialog';
import { pmojiData, pmojiHead, pmojiType } from 'types/entities/pmoji';
import {
  DeletedPmoji,
  EditPmoji,
  Pmoji,
  PmojiPagination
} from 'types/pmoji.interface';
import EditPmojiDialog from './components/EditPmojiDialog';
import './styles.scss';

const PmojiManagement = () => {
  const [category, setCategory] = useState<string>('name');
  const [check, setCheck] = useState<CheckBoxDefault[]>(pmojiType);
  const [keySearch, setKeySearch] = useState<string>('');
  const [data, setData] = useState<PmojiPagination>(pmojiData);
  const [pmojiList, setPmojiList] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState<DialogState<Pmoji>>({
    open: false,
    data: null
  });
  const [openDialogResult, setOpenDialogResult] =
    useState<DialogResultProps>(dialogResult);

  const handleInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setKeySearch(event.target.value);
  };

  const handleCategoryChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setCategory(event.target.value);
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const curVal: CheckBoxDefault[] = [...check];
    const newVal: CheckBoxDefault[] = curVal.map((val: CheckBoxDefault) => {
      if (val.name === event.target.name) {
        val.value = event.target.checked;
      }
      return val;
    });
    setCheck(newVal);
  };
  const getPmojiList = async () => {
    const response = await PmojiService.getPmojiList({
      page: data?.current_page || 1,
      keyword: keySearch || '',
      select: category,
      isSkin: check[0].value || '',
      isHair: check[1].value || '',
      isFashion: check[2].value || '',
      isCharacterSkin: check[3].value || ''
    });
    let newData: Pmoji[] = [...response?.data?.data?.pmojis];

    const deletedPmojis: DeletedPmoji[] = JSON.parse(
      localStorage.getItem('deletedIds') ?? '[]'
    );

    if (deletedPmojis && deletedPmojis.length) {
      deletedPmojis.forEach((data: DeletedPmoji) => {
        const index = newData.findIndex(
          (item: Pmoji) => item?.id === data?.id && item?.type === data?.type
        );
        if (index !== -1) {
          newData.splice(index, 1);
        }
      });
    }

    const pmojiList: EditPmoji[] = JSON.parse(
      localStorage.getItem('pmojiList') ?? '[]'
    );

    if (pmojiList && pmojiList.length) {
      pmojiList.forEach((item: EditPmoji) => {
        const index = newData.findIndex(
          (data: Pmoji) =>
            data?.id === item?.id && data?.type === item?.typePmoji
        );
        if (index !== -1) {
          newData[index] = { ...newData[index], ...item };
        }
      });
    }
    setData((prevState: PmojiPagination) => {
      return {
        ...prevState,
        total_pages: response?.data?.meta?.pagination?.total_pages,
        total: response?.data?.meta?.pagination?.total,
        data: {
          pmojis: newData
          // totalPmoji: response?.data?.data?.totalPmoji
        }
      };
    });
    const bodyTables = newData.map((item: Pmoji) => {
      return {
        pmojiNumber: item?.pmojiNumber,
        fileName: item?.fileName,
        pmojiType: item?.type,
        imagePreview: [{ url: item?.url + `?${Date.now()}` }],
        edit: '더보기'
      };
    }) as [];
    setPmojiList(bodyTables);
  };

  const showDetail = async (index: number) => {
    setOpenEditDialog({
      open: true,
      data: data?.data?.pmojis[index] ?? null
    });
  };

  const handlePaginationChange = (_: {}, p: number) => {
    setData((prevState: PmojiPagination) => {
      return { ...prevState, current_page: p };
    });
  };

  const handleSearch = () => {
    if (data.current_page !== 1) {
      setData((prevState: PmojiPagination) => {
        return { ...prevState, current_page: 1 };
      });
    } else {
      getPmojiList();
    }
  };

  useEffect(() => {
    getPmojiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.current_page, check]);

  const handleUpdate = () => {
    const deletedPmojis: DeletedPmoji[] = JSON.parse(
      localStorage.getItem('deletedIds') ?? '[]'
    );
    const pmojiList: EditPmoji[] = JSON.parse(
      localStorage.getItem('pmojiList') ?? '[]'
    );
    if (pmojiList && pmojiList.length) {
      const promiseList: Promise<any>[] = [];
      pmojiList.forEach((item: EditPmoji) => {
        promiseList.push(PmojiService.updatePmoji(item));
      });
      Promise.all(promiseList)
        .then((res) => {
          if (res) {
            localStorage.removeItem('pmojiList');
            setOpenDialogResult({
              open: true,
              title: '',
              message: '업데이트가 완료되었습니다.',
              btnText: '확인'
            });
            getPmojiList();
          }
        })
        .catch((error) => {
          localStorage.removeItem('pmojiList');
          setOpenDialogResult({
            open: true,
            message: '업데이트를 재시도해주세요.',
            title: 'api 오류가 발생하였습니다.',
            btnText: '확인'
          });
          getPmojiList();
        });
    } else if (deletedPmojis && deletedPmojis.length) {
      const promiseList: Promise<any>[] = [];
      deletedPmojis.forEach((item: DeletedPmoji) => {
        promiseList.push(PmojiService.deletePmoji(item.id, item.type));
      });
      Promise.all(promiseList)
        .then((res) => {
          if (res) {
            localStorage.removeItem('deletedIds');
            setOpenDialogResult({
              open: true,
              title: '',
              message: '업데이트가 완료되었습니다.',
              btnText: '확인'
            });
            getPmojiList();
          }
        })
        .catch((error) => {
          localStorage.removeItem('deletedIds');
          setOpenDialogResult({
            open: true,
            message: '업데이트를 재시도해주세요.',
            title: 'api 오류가 발생하였습니다.',
            btnText: '확인'
          });
          getPmojiList();
        });
    } else {
      setOpenDialogResult({
        open: true,
        title: '',
        message: '수정사항이 없습니다.',
        btnText: '확인'
      });
    }
  };

  return (
    <div className="main-content pmoji-management">
      <div className="pmoji-management-content">
        <div className="section-update">
          <Box className="update-button">
            <ButtonCustom
              title="업데이트"
              cate="standard"
              onClick={handleUpdate}
            />
          </Box>
        </div>
        <div className="section-search">
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <div className="search-contain">
                <FormControl sx={{ m: 1 }} className="selection">
                  <Select value={category} onChange={handleCategoryChange}>
                    <MenuItem className="menu-item" value="pmojiNumber">
                      번호
                    </MenuItem>
                    <MenuItem className="menu-item" value="name">
                      프모지 파일명
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  className="input-search"
                  value={keySearch}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter' || e.code === 'NumpadEnter')
                      handleSearch();
                  }}
                />
                <Box className="search-button">
                  <ButtonCustom
                    title="검색"
                    cate="standard"
                    onClick={handleSearch}
                  />
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} md={2}>
              <span className="number-searched">(총 : {data?.total}건)</span>
            </Grid>
          </Grid>
          {/* Checkbox group */}
          <Box className="checkbox-group">
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormGroup>
                {check && check.length
                  ? check.map((item: CheckBoxDefault, index: number) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={item.value}
                            onChange={(e) => handleCheck(e)}
                            name={item.name}
                            sx={{
                              color: '#FF6151',
                              '&.Mui-checked': {
                                color: '#FF6151'
                              }
                            }}
                          />
                        }
                        label={item.label}
                      />
                    ))
                  : null}
              </FormGroup>
            </FormControl>
          </Box>
        </div>
        <div className="section-pmoji-list">
          <TabelComponent
            dataHead={pmojiHead}
            databody={pmojiList}
            actionEdit={showDetail}
          />
          <EditPmojiDialog
            open={openEditDialog.open}
            data={openEditDialog.data}
            handleClose={() => setOpenEditDialog({ open: false, data: null })}
            handleRefresh={getPmojiList}
          />
        </div>
        <Pagination
          className="section-pagination"
          count={data?.total_pages || 1}
          page={data?.current_page || 1}
          onChange={handlePaginationChange}
          variant="outlined"
        />
        <DialogResult
          open={openDialogResult.open}
          title={openDialogResult.title}
          message={openDialogResult.message}
          btnText={openDialogResult.btnText}
          handleClose={() =>
            setOpenDialogResult({
              open: false,
              message: '',
              title: '',
              btnText: ''
            })
          }
          handleOk={() =>
            setOpenDialogResult({
              open: false,
              message: '',
              title: '',
              btnText: ''
            })
          }
        />
      </div>
    </div>
  );
};

export default PmojiManagement;
