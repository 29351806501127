import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { UserState } from 'types/user.interface';
import { getUserInfo, signIn } from './userThunk';

const initialState: UserState = {
  userInfo: {
    bio: '',
    alias: '',
    nickname: '',
    phone: '',
    status: '',
    role: ''
  },
  accessToken: '',
  refreshToken: '',
  isLoading: false,
  error: {}
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      state.userInfo = {
        bio: '',
        alias: '',
        nickname: '',
        phone: '',
        status: '',
        role: ''
      };
    },
    setUser: (state, action) => {
      state.userInfo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userInfo = action.payload['user'];
        state.accessToken = action.payload['accessToken'];
        state.refreshToken = action.payload['refreshToken'];
      })
      .addCase(signIn.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload;
      })
  }
});

export const { reset, setUser } = userSlice.actions;

export const getUser = (state: RootState) => state.user.userInfo;
export const getToken = (state: RootState) => state.user.accessToken;
export const getStatus = (state: RootState) => state.user.isLoading;
export const getError = (state: RootState) => state.user.error;

export default userSlice.reducer;
