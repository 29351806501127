import { EditPmoji, PmojiParams } from 'types/pmoji.interface';
import api from './api';

const getPmojiList = (params: PmojiParams) => {
  return api.get(
    `/api/v1/pmoji?page=${params.page}&limit=25&order=ASC&keyword=${params.keyword}&select=${params.select}&isHair=${params.isHair}&isSkin=${params.isSkin}&isCharacterSkin=${params.isCharacterSkin}&isFashion=${params.isFashion}&orderField=id`
  );
};

const getPmojiByID = (id: number, type: string) => {
  return api.post(`/api/v1/pmoji/${id}`, { type });
};

const uploadImage = (body: FormData) => {
  return api.post(`/api/v1/pmoji/upload`, body);
};

const updatePmoji = ({ id, fileName, imageId, typePmoji }: EditPmoji) => {
  return api.put(`/api/v1/pmoji/${id}`, { fileName, imageId, typePmoji });
};

const deletePmoji = (pmojiId: number | undefined, typePmoji: string) => {
  return api.delete(`/api/v1/pmoji/${pmojiId}`, { data: { typePmoji } });
};

const checkPmojiName = (id: number, type: string, fileName: string) => {
  return api.post(`/api/v1/pmoji/fileName/check`, { id, type, fileName });
};

const PmojiService = {
  getPmojiList,
  getPmojiByID,
  uploadImage,
  updatePmoji,
  deletePmoji,
  checkPmojiName
};

export default PmojiService;
