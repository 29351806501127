import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import TabelComponent from 'elements/TableCell';
import React, { ChangeEvent, useEffect, useState } from 'react';
import SugarService, { PatchSugar } from 'services/sugar.service';
import { DialogTitleProps } from 'types/dialog.interface';
import { sugarHeadDetail } from 'types/entities/sugar';
import { dateFormat } from 'utils/date';
import './styles.scss';
import Typography from '@mui/material/Typography';
import Swal from 'sweetalert2';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

function SugarDetailDialogV2({
  open,
  id,
  handleRefresh,
  handleClose
}: {
  open: boolean;
  id: any;
  handleClose: () => void;
  handleRefresh: () => void;
}) {
  const [data, setData] = useState<any>();
  const [changeSugar, setChangeSugar] = useState<boolean>(false);
  const [changeCategory, setChangeCategory] = useState<string>('add');
  const [changeSugarValue, setChangeSugarValue] = useState<number>(0);
  const [currentValue, setCurrentValue] = useState<number | string>();
  const [changeCount, setChangeCount] = useState<number>(0);
  const [sugarHistoryList, setHistoryList] = useState<any[]>([]);

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  const handleCloseDialog = () => {
    setChangeCategory('add');
    setChangeSugarValue(0);
    handleClose();
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    async function getSugarDetail() {
      try {
        const response = await SugarService.getSugarDetail(id);
        setData(response?.data?.data);
        const newCurrentValue = response?.data?.data?.user?.currentSugarQuantity;
        setCurrentValue(newCurrentValue);
        const bodyTable = response?.data?.data?.histories.map((item: any) => {
          let typeText = '';
          switch (item?.type) {
            case 'ADMIN_CHANGE':
              typeText = '관리자 수정';
              break;
            case 'CHECK_IN':
              typeText = '로그인';
              break;
            case 'FIRST_QUESTION':
              typeText = '첫 질문';
              break;
            case 'FIRST_POST':
              typeText = '첫 게시물';
              break;
            case 'FIRST_LOGIN':
              typeText = '최초가입';
              break;
            case 'BEST_ANSWER':
              typeText = '답변 채택';
              break;
            case 'OWNER_QUESTION':
              typeText = '질문';
              break;
            case 'FIRST_ANSWER':
              typeText = '첫 답변';
              break;
            case 'ANSWER_REPORTED':
              typeText = '댓글 신고';
              break;
            case 'ANSWER_DELETED':
              typeText = '답변 삭제';
              break;

            case 'QUESTION_DELETED':
              typeText = '질문 삭제';
              break;
            case 'ADD_BY_ADMIN':
              typeText = '관리자에 의한 지급';
              break;
            case 'WITHDRAW_BY_ADMIN':
              typeText = '관리자에 의한 출금';
              break;
            case 'SUB_BY_ADMIN':
              typeText = '관리자에 의한 회수';
              break;
            default:
              typeText = '';
              break;
          }
          return {
            checkDate: dateFormat(item?.created_at, 'YY.MM.DD')
              ? dateFormat(item?.created_at, 'YY.MM.DD')
              : '-',
            type: typeText ? typeText : '-',
            value: item?.value
              ? item.value > 0
                ? '+' + item.value + ' 설탕'
                : item.value + ' 설탕'
              : '-',
            total: item?.totalSugarHistories
          };
        }) as [];
        setHistoryList(bodyTable);
      } catch (error) {
        console.error('Error fetching sugar detail:', error);
      }
    }

    getSugarDetail();
  }, [changeCount, id]);

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseDialog}
        open={open}
        className="edit-sugar-dialog"
      >
        <BootstrapDialogTitle
          id="edit-sugar-dialog"
          onClose={handleCloseDialog}
        >
          회원 설탕 관리
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="info-item">
            <p className="label">회원 번호</p>
            <p className="value">{data?.user?.id ? data?.user?.id : '-'}</p>
          </div>
          <div className="info-item">
            <p className="label">휴대폰 번호</p>
            <p className="value">
              {data?.user?.phone ? data?.user?.phone : '-'}
            </p>
          </div>
          <div className="info-item">
            <p className="label">활동 아이디</p>
            <p className="value">
              {data?.user?.alias ? data?.user?.alias : '-'}
            </p>
          </div>
          <div className="info-item">
            <p className="label">현재 설탕 수</p>
            <p className="value">{currentValue}</p>
            <Button variant="outlined"
                    onClick={() => setChangeSugar(!changeSugar)}>
              변경
            </Button>
          </div>
        </DialogContent>

        {changeSugar && (
          <Grid container spacing={2} py={2} px={1}>
            <Grid item xs={2}>
              <Select
                fullWidth
                value={changeCategory}
                onChange={(e: SelectChangeEvent) => setChangeCategory(e.target.value)}
                displayEmpty
              >
                <MenuItem
                  className="menu-item"
                  key={'add'}
                  value={'add'}
                >
                  <Typography color='blue'>지급</Typography>
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  key={'withdraw'}
                  value={'withdraw'}
                >
                  <Typography color='orange'>출금</Typography>
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  key={'sub'}
                  value={'sub'}
                >
                  <Typography color='orange'>회수</Typography>
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                type={'number'}
                variant="outlined"
                value={changeSugarValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setChangeSugarValue(Number(e.target.value))} />
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth
                      variant="contained"
                      onClick={async () => {
                        const userId = data?.user?.id;

                        const isSucces = await PatchSugar(userId, changeSugarValue, changeCategory);

                        if (!isSucces) {
                          await Swal.fire('오류 발생', '오류가 발생했습니다.', 'error');
                          return;
                        }

                        setChangeSugar(false);
                        setChangeCount(changeCount + 1);
                      }}
                      style={{
                        height: '100%'
                      }}>
                적용</Button>
            </Grid>
          </Grid>
        )}

        <Box className="sugar-history">설탕 히스토리</Box>

        <TabelComponent
          margin="0 0 30px"
          height="300px"
          width="500px"
          background="#F8F8FB"
          dataHead={sugarHeadDetail}
          databody={sugarHistoryList}
        />
      </BootstrapDialog>
    </>
  );
}

export default SugarDetailDialogV2;
