import React from "react";

const EyeCrossIcon = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.29656 2.02934C4.26562 1.27465 5.50812 0.699996 7 0.699996C8.7675 0.699996 10.1828 1.50587 11.2131 2.46312C12.2369 3.4125 12.9216 4.53031 13.2453 5.33093C13.3175 5.50375 13.3175 5.69625 13.2453 5.86906C12.9522 6.57562 12.3637 7.57093 11.4975 8.45906L13.7987 10.2616C14.0262 10.4409 14.0678 10.7712 13.8884 10.9987C13.7091 11.2262 13.3787 11.2678 13.1512 11.0884L0.201159 0.938215C-0.0270414 0.759277 -0.0670508 0.429402 0.111821 0.201159C0.290715 -0.0270414 0.62059 -0.0670508 0.848965 0.111821L3.29656 2.02934ZM4.88031 3.27031L6.85562 4.81906C6.9475 4.63312 7 4.42312 7 4.18031C7 3.94843 6.91468 3.71218 6.81625 3.50875C6.8775 3.50218 6.93875 3.48031 7 3.48031C8.15937 3.48031 9.1 4.42093 9.1 5.58031C9.1 5.89968 9.03656 6.18406 8.90531 6.44218L9.76937 7.10281C10.0122 6.65656 10.15 6.14468 10.15 5.58031C10.15 3.86093 8.73906 2.43031 7 2.43031C6.18406 2.43031 5.43812 2.76062 4.88031 3.27031ZM7 10.5C5.2325 10.5 3.81718 9.695 2.78687 8.73687C1.76356 7.76781 1.07931 6.65 0.753809 5.86906C0.682059 5.69625 0.682059 5.50375 0.753809 5.33093C0.962496 4.83 1.31884 4.1825 1.81759 3.53281L3.88062 5.15812C3.86093 5.3025 3.85 5.44906 3.85 5.58031C3.85 7.33906 5.26093 8.75 7 8.75C7.40906 8.75 7.80062 8.67125 8.15937 8.52906L9.76062 9.78906C8.96656 10.2178 8.04562 10.5 7 10.5Z"
        fill="#3C4651"
      />
    </svg>
  );
};

export default EyeCrossIcon;
