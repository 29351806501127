import CloseIcon from '@material-ui/icons/Close';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, IconButton } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Image from 'elements/Image';
import { useState } from 'react';
import { PreviewImageProps } from 'types/image.interface';
import './styles.scss';
const PreviewImage = ({ image, open, onClose }: PreviewImageProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < image?.src?.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : image?.src?.length - 1
    );
  };

  return (
    <div className="preview-img">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <div className="preview-img-container">
          <div className="preview-img-content">
            {image?.src?.length && (
              <IconButton
                aria-label="previous"
                className="prev-icon"
                onClick={handlePrevious}
                sx={{
                  zIndex: '10',
                  position: 'absolute',
                  left: 4,
                  top: '45%',
                  color: '#000000',
                  display: currentImageIndex === 0 ? 'none' : '',
                  background: '#ffffff54'
                }}
              >
                <ArrowLeftIcon sx={{ fontSize: '40px' }} />
              </IconButton>
            )}

            <IconButton
              aria-label="close"
              className="close-icon"
              onClick={() => {
                onClose();
                setCurrentImageIndex(0);
              }}
              sx={{
                position: 'absolute',
                right: 4,
                top: 4,
                color: '#FF6151'
              }}
            >
              <CloseIcon />
            </IconButton>
            {image?.src?.length && (
              <IconButton
                aria-label="next"
                className="next-icon"
                onClick={handleNext}
                sx={{
                  zIndex: '10',
                  position: 'absolute',
                  right: 4,
                  top: '45%',
                  color: '#000000',
                  display:
                    currentImageIndex === image?.src?.length - 1 ? 'none' : '',
                  background: '#ffffff54'
                }}
              >
                <ArrowRightIcon sx={{ fontSize: '40px' }} />
              </IconButton>
            )}

            <Image
              src={image?.src && image?.src[currentImageIndex]?.url}
              alt={image?.src && image?.src[currentImageIndex]?.url}
            />
            {image?.src?.length && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '11px',
                  background: '#000000',
                  padding: ' 3px 30px',
                  borderRadius: '20px'
                }}
              >
                {currentImageIndex + 1} / {image?.src?.length}
              </Box>
            )}
          </div>
        </div>
      </Backdrop>
    </div>
  );
};

export default PreviewImage;
