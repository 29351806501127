import React from 'react';
import { Stack } from '@mui/material';

interface RootLayoutsProps {
  children: React.ReactNode;
}

export default function RootLayouts({ children }: RootLayoutsProps) {
  return (
    <Stack className="main-content">{children}</Stack>
  );
}