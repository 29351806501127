export const categories = [
  { value: 'id', label: '회원번호' },
  { value: 'uniqueId', label: '고유 아이디' },
  { value: 'alias', label: '아이디' },
  { value: 'nickname', label: '닉네임' },
  { value: 'phone', label: '휴대폰 번호' },
  { value: 'status', label: '정지여부' }
];

export const memberInfo = {
  current_page: 1,
  total_pages: 1,
  total: 0,
  data: {
    user: [],
    totalReportedMembers: 0
    // totalUserRegistered: 0
  }
};

export const membersHead = [
  { name: '회원번호' },
  { name: '고유 아이디' },
  { name: '프모지' },
  { name: '아이디' },
  { name: '닉네임' },
  { name: '휴대폰 번호' },
  { name: '마케팅 수신동의' },
  { name: '신고받은 수' },
  { name: '공유 수' },
  { name: '가입 날짜' },
  { name: '정지여부' }
];
