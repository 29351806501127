import api from "./api";
import { User } from "types/user.interface";

const signIn = (data: User) => {
  return api.post(`/api/v1/authentication/admin/login`, { alias: data.email, password: data.password });
};

const getUserInfo = () => {
  return api.get(`/api/v1/user/info`);
};

const UserService = {
  signIn,
  getUserInfo
};

export default UserService;
