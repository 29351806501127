import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

interface ModalRootProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  modalName: string;
}

export default function ModalRoot({
  open,
  onClose,
  children,
  modalName,
}: ModalRootProps) {
  return (
    <Modal open={open}
           onClose={onClose}
           aria-labelledby={modalName}
           aria-describedby={`${modalName}-description`}>
      <Box p={4}
           position='absolute'
           top='50%'
           left='50%'
           width='60%'
           bgcolor='background.paper'
           border='2px solid #000'
           boxShadow={24}
           sx={{
             transform: 'translate(-50%, -50%)',
           }}>
        {children}
      </Box>
    </Modal>
  );
}
