import axios, { AxiosResponse } from 'axios';
import api from './api';

export interface PresignedUrl extends AxiosResponse {
  url: string;
  fields: {
    bucket: string;
    key: string;
    Policy: string;
    'X-Amz-Algorithm': string;
    'X-Amz-Credential': string;
    'X-Amz-Date': string;
    'X-Amz-Signature': string;
  };
  uploadedUrl: string;
}

export const GetPresignedUrl = async (): Promise<PresignedUrl> => {
  const { data } = await api.get('/api/admin/v2/uploads/presigned-url');

  return data;
}

interface UploadFileUsingPresignedUrlRequest {
  url: string;
  'X-Amz-Algorithm': string;
  'X-Amz-Credential': string;
  'X-Amz-Signature': string;
  'X-Amz-Date': string;
  bucket: string;
  key: string;
  Policy: string;
  'Content-Type': string;
  file: File;
}

export async function UploadFileUsingPresignedUrl(request: UploadFileUsingPresignedUrlRequest) {
  const form= new FormData();
  form.append('X-Amz-Algorithm', request['X-Amz-Algorithm']);
  form.append('X-Amz-Credential', request['X-Amz-Credential']);
  form.append('X-Amz-Signature', request['X-Amz-Signature']);
  form.append('X-Amz-Date', request['X-Amz-Date']);
  form.append('bucket', request.bucket);
  form.append('key', request.key);
  form.append('Policy', request.Policy);
  form.append('Content-Type', request.file.type);
  form.append('file', request.file);

  try {
    await axios.post(request.url, form)

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}


