export const adminData = {
  current_page: 1,
  total_pages: 1,
  data: []
};

export const adminHead = [
  { name: '순번' },
  { name: '관리자 아이디' },
  { name: '권한' },
  { name: '가입 날짜' },
  { name: '계정 삭제' }
];
