import './styles.scss';
import Pagination from '@mui/material/Pagination';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { AdminDetail, AdminPagination } from 'types/admin.interface';
import { adminData, adminHead } from 'types/entities/admin';
import AddAdminDialog from './components/AddAdminDialog';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TabelComponent from 'elements/TableCell';
import AdminService from 'services/admin.service';

const AdminAccount = () => {
  const [data, setData] = useState<AdminPagination>(adminData);
  const [open, setOpen] = useState<{ open: boolean; alias: string }>({
    open: false,
    alias: ''
  });
  const [adminList, setAdminList] = useState<AdminDetail[]>([]);

  const getAdminList = async () => {
    const res = await AdminService.getAdminList(data?.current_page || 1);
    const { total_pages, current_page } = res?.data?.meta?.pagination;
    if (total_pages && current_page > total_pages) {
      setData((prevState: AdminPagination) => {
        return { ...prevState, current_page: 1 };
      });
    } else {
      setData((prevState: AdminPagination) => {
        return {
          ...prevState,
          total_pages: res?.data?.meta?.pagination?.total_pages,
          data: res?.data?.data?.user
        };
      });
      const bodyTable = res?.data?.data?.user.map((item: AdminDetail) => {
        return {
          numberAccount: (data?.current_page - 1) * 10,
          alias: item?.alias,
          role: item?.role,
          adminCreatedAt: item?.created_at,
          deleteAdmin: '계정 삭제'
        };
      }) as [];
      setAdminList(bodyTable);
    }
  };

  const handlePaginationChange = (_: {}, p: number) => {
    setData((prevState: AdminPagination) => {
      return { ...prevState, current_page: p };
    });
  };

  const deleteAdmin = async (index: number) => {
    const id = data?.data[index]?.id ?? null;
    const res = await AdminService.deleteAdmin(id);
    if (res) {
      getAdminList();
    }
  };

  const showDetail = async (index: number) => {
    // const res = await MemberService.getMemberByID(id);
    const alias = data?.data[index]?.alias ?? '';
    setOpen({
      open: true,
      alias
    });
  };

  const handleRefresh = () => {
    if (data.current_page !== 1) {
      setData((prevState: AdminPagination) => {
        return { ...prevState, current_page: 1 };
      });
    } else {
      getAdminList();
    }
  };

  useEffect(() => {
    getAdminList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.current_page]);

  return (
    <div className="main-content admin-account">
      <div className="admin-account-content">
        <div className="section-actions">
          <div className="add-button">
            <Button
              variant="text"
              startIcon={<AddCircleIcon />}
              onClick={() => setOpen({ open: true, alias: '' })}
            >
              계정 추가
            </Button>
          </div>
        </div>
        <div className="section-admin-list">
          <TabelComponent
            dataHead={adminHead}
            databody={adminList}
            actionDelete={deleteAdmin}
            actionShow={showDetail}
          />
        </div>
        <Pagination
          className="section-pagination"
          count={data?.total_pages || 1}
          page={data?.current_page || 1}
          onChange={handlePaginationChange}
          variant="outlined"
        />
        <AddAdminDialog
          open={open.open}
          id={open.alias}
          onClose={() => setOpen({ open: false, alias: '' })}
          refresh={handleRefresh}
        />
      </div>
    </div>
  );
};

export default AdminAccount;
