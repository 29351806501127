import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const DialogError = ({
  open,
  message,
  handleClose
}: {
  open: boolean;
  message: string;
  handleClose: () => void;
}) => {
  const handleCloseDialog = (event: {}, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  return (
    <div>
      <Dialog
        className="dialog-confirm"
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className="dialog-confirm-content dialog-login-fail-content"
            id="alert-dialog-description"
          >
            {message}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogError;
