import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { categories } from '../../../types/entities/member';
import { SearchSection } from './components/SearchSection';
import { ContentsSection } from './components/ContentsSection';
import { FetchAllReportedUsers } from '../../../services/userReports/fetch-user-reports';
import { ReportedUser } from '../../../services/userReports/types';
import UserModal from '../../../modals/UserModal';
import RootLayouts from '../../../layouts/RootLayouts';

interface RowDef {
  id: number;
  alias: string;
  nickname: string;
  phone: string;
  allowPushNotification: boolean;
  recentReportedAt: string;
  reportCount: number;
  isSuspension: boolean;
  createdAt: string;
}

const useReported = () => {
  const [searchCategory, setSearchCategory] = useState<string>('nickname');
  const [searchValue, setSearchValue] = useState<string>('');
  const [allowMarketingPush, setAllowMarketingPush] = useState<boolean>(true);
  const [isSuspension, setIsSuspension] = useState<boolean>(false);
  const [userModalOpen, setUserModalOpen] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number>(-1);
  const [allReportedUsers, setAllReportedUsers] = useState<ReportedUser[]>([]);
  const [rows, setRows] = useState<RowDef[]>([]);

  const onCloseUserModal = () => {
    setUserModalOpen(false);
    setSelectedUserId(-1)
  }

  useEffect(() => {
    async function fetchAllReportedUsers() {
      const fetchedReportedUsers: ReportedUser[] = await FetchAllReportedUsers();
      setAllReportedUsers(fetchedReportedUsers);
    }

    fetchAllReportedUsers();
  }, []);

  const searchFilter = useCallback(() =>
    allReportedUsers.filter((reportedUser: ReportedUser) => {
      if (allowMarketingPush !== reportedUser.allowMarketingPushNotification) {
        return false;
      }

      if (isSuspension !== reportedUser.isSuspension) {
        return false;
      }

      if (searchValue) {
        switch (searchCategory) {
          case 'id':
            if (!reportedUser.id.toString().includes(searchValue)) {
              return false;
            }
            break;
          case 'uniqueId':
            if (!reportedUser.uniqueId.includes(searchValue)) {
              return false;
            }
            break;
          case 'alias':
            if (!reportedUser.alias.toLowerCase().includes(searchValue.toLowerCase())) {
              return false;
            }
            break;
          case 'nickname':
            if (!reportedUser.nickname.toLowerCase().includes(searchValue.toLowerCase())) {
              return false;
            }
            break;
          case 'phone':
            if (!reportedUser.phone.includes(searchValue)) {
              return false;
            }
            break;
          default:
            return false;
        }
      }

      return true;
    }), [allReportedUsers, allowMarketingPush, isSuspension, searchCategory, searchValue]);

  const toRows = function(users: ReportedUser[]): RowDef[] {
    return users.map((reportedUser: ReportedUser) => ({
      id: reportedUser.id,
      alias: reportedUser.alias,
      nickname: reportedUser.nickname,
      phone: reportedUser.phone,
      allowPushNotification: reportedUser.allowMarketingPushNotification,
      recentReportedAt: reportedUser.recentReportedAt,
      reportCount: reportedUser.reportedUsersCount,
      isSuspension: reportedUser.isSuspension,
      createdAt: reportedUser.createdAt,
    }));
  }

  useEffect(() => {
    if (!allReportedUsers || !allReportedUsers.length) {
      return;
    }

    const filteredReportedUsers = searchFilter();
    setRows(toRows(filteredReportedUsers));
  }, [allReportedUsers, searchFilter]);

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: '',
      width: 100,
      renderCell: (params: GridRenderCellParams<RowDef>) => (
        <strong>
          <Button variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setUserModalOpen(true);
                    setSelectedUserId(params.row.id);
                  }}>
            상세보기
          </Button>
        </strong>
      ),
    },
    {
      field: 'id',
      headerName: '회원번호',
    },
    {
      field: 'alias',
      headerName: '아이디',
    },
    {
      field: 'nickname',
      headerName: '닉네임',
    },
    {
      field: 'phone',
      headerName: '휴대폰 번호',
    },
    {
      field: 'allowPushNotification',
      type: 'boolean',
      headerName: '마케팅 수신동의',
    },
    {
      field: 'recentReportedAt',
      headerName: '최근 신고받은 일시',
    },
    {
      field: 'reportCount',
      headerName: '신고 받은 수',
    },
    {
      field: 'isSuspension',
      type: 'boolean',
      headerName: '정지유무',
    },
    {
      field: 'createdAt',
      headerName: '가입 날짜',
    }
  ];

  const handleSearch = useCallback(() => {
    const filteredReportedUsers = searchFilter();
    setRows(toRows(filteredReportedUsers));
  }, [searchFilter]);

  return {
    models: {
      searchCategory,
      searchValue,
      allowMarketingPush,
      isSuspension,
      columns,
      rows,
      userModalOpen,
      selectedUserId,
    },
    operations: {
      setSearchCategory,
      setSearchValue,
      setAllowMarketingPush,
      handleSearch,
      setIsSuspension,
      onCloseUserModal,
    },
  };
};



export default function Reported() {
  const { models, operations } = useReported();

  return (
    <RootLayouts>
      <SearchSection>
        <Grid container
              spacing={2}
              mb={.5}>
          <Grid item>
            <FormGroup>
              <FormControlLabel control={<Checkbox />}
                                label="마케팅 푸시 수신 동의여부"
                                checked={models.allowMarketingPush}
                                onChange={(e: React.SyntheticEvent, checked: boolean) => operations.setAllowMarketingPush(checked)} />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormControlLabel control={<Checkbox />}
                                label="정지여부"
                                checked={models.isSuspension}
                                onChange={(e: React.SyntheticEvent, checked: boolean) => operations.setIsSuspension(checked)} />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item
                xs={12}>
            <FormGroup row>
              <Select value={models.searchCategory}
                      onChange={(e: SelectChangeEvent<string>) => operations.setSearchCategory(e.target.value as string)}
                      displayEmpty
                      sx={{
                        width: '10%',
                      }}>
                {categories && categories.length
                  ? categories
                    .filter(category => category.value !== 'status')
                    .map((item) => (
                      <MenuItem key={item.value}
                                value={item.value}>{item.label}</MenuItem>
                    ))
                  : <></>}
              </Select>
              <TextField label="검색할 값을 입력하세요"
                         variant="outlined"
                         onChange={(e: React.ChangeEvent<HTMLInputElement>) => operations.setSearchValue(e.target.value)}
                         sx={{
                           width: '90%',
                         }} />
            </FormGroup>
          </Grid>
        </Grid>
      </SearchSection>

      <ContentsSection>
        <DataGrid columns={models.columns}
                  rows={models.rows}
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: {
                        page: 0,
                        pageSize: 20,
                      },
                    },
                  }} />
      </ContentsSection>

      <section>
        <UserModal open={models.userModalOpen}
                   onClose={operations.onCloseUserModal}
                   userId={models.selectedUserId} />
      </section>
    </RootLayouts>
  );
}
