import { Button } from '@mui/material';
import { useCallback } from 'react';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DownloadIcon from '@mui/icons-material/Download';

interface ExcelDownLoadButtonProps {
  headers: string[];
  data: string[][];
  excelFileName: string;
  sheetName: string;
}

const useExcelDownLoadButton = (
  headers: string[],
  data: string[][],
  excelFileName: string,
  sheetName: string,
) => {
  const onClickDownload = useCallback(() => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const ws = XLSX.utils.aoa_to_sheet(
      [
        headers,
        ...data
      ]
    );

    const sheets: {
      [key: string]: XLSX.WorkSheet;
    } = {};
    sheets[sheetName] = ws;

    const wb: any = {
      Sheets: sheets,
      SheetNames: [sheetName]
    };

    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
    const excelFile = new Blob([excelButter], { type: excelFileType});
    FileSaver.saveAs(excelFile, `${excelFileName}.xlsx`);
  }, [data, excelFileName, headers, sheetName]);

  return {
    operations: {
      onClickDownload,
    },
  };
}

export default function ExcelDownLoadButton({
  headers,
  data,
  excelFileName,
  sheetName,
}: ExcelDownLoadButtonProps) {
  const { operations } = useExcelDownLoadButton(headers, data, excelFileName, sheetName);

  return (
    <Button variant="contained"
            onClick={operations.onClickDownload}
            sx={{
              backgroundColor: "#1D6F42",
              color: "white",
            }}
    >
      <DownloadIcon fontSize="small" />&nbsp;&nbsp;엑셀 다운로드
    </Button>
  );
}
