export enum Prendies {
  BEGINNER = 'BEGINNER',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PRO = 'PRO',
}

export enum PrendyApplicationStatus {
  APPLICATION = 'APPLICATION',
  APPROVAL = 'APPROVAL',
  REJECT = 'REJECT',
}

export const switchApplicationStatus = (status: string) => {
  switch (status) {
    case PrendyApplicationStatus.APPLICATION:
      return '신청중';
    case PrendyApplicationStatus.APPROVAL:
      return '승인됨';
    case PrendyApplicationStatus.REJECT:
      return '거절됨';
    default:
      return status;
  }
}
