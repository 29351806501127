import RequireAuth from 'guard';
import Layout from 'layouts';
import { Navigate, useRoutes } from 'react-router-dom';

import AdminAccount from 'pages/AdminAccount';
import ContentManagement from 'pages/ContentManagement';
import MemberInformation from 'pages/MemberInformation';
import PlaceManagement from 'pages/PlaceManagement';
import PmojiManagement from 'pages/PmojiManagement';
import Login from 'pages/User/Login';
import { CommandPopupStores } from '../pages/PopupStores/Command';
import PopupStores from '../pages/PopupStores';
import Prendies from '../pages/Prendies';
import Sugars from '../pages/Users/Sugars';
import Dashboard from '../pages/Dashboards';
import OpenNotificationApplications from '../pages/PopupStores/OpenNotificationApplications';
import Reported from '../pages/Users/Reported';

function Routes() {
  const routes = useRoutes([
    {
      element: <Layout />,
      children: [
        {
          element: <RequireAuth />,
          children: [
            { path: '/', element: <Navigate to="/dashboards" /> }
          ]
        },
        { path: '/login', element: <Login /> },
        {
          element: <RequireAuth />,
          children: [{ path: '/dashboards', element: <Dashboard /> }]
        },
        {
          element: <RequireAuth />,
          children: [
            { path: '/member-information', element: <MemberInformation /> }
          ]
        },
        {
          element: <RequireAuth />,
          children: [
            { path: '/place-management', element: <PlaceManagement /> }
          ]
        },
        {
          element: <RequireAuth />,
          children: [
            { path: '/pmoji-management', element: <PmojiManagement /> }
          ]
        },
        {
          element: <RequireAuth />,
          children: [
            { path: '/content-management', element: <ContentManagement /> }
          ]
        },
        {
          element: <RequireAuth />,
          children: [{ path: '/admin-account', element: <AdminAccount /> }]
        },
        {
          element: <RequireAuth />,
          children: [
            { path: '/users/reports', element: <Reported /> },
            { path: '/users/sugars', element: <Sugars /> },
          ]
        },
        {
          element: <RequireAuth />,
          children: [
            { path: '/popup-stores', element: <PopupStores /> },
            { path: '/popup-stores/create', element: <CommandPopupStores /> },
            { path: '/popup-stores/open-notification-application', element: <OpenNotificationApplications /> },
            { path: '/popup-stores/:id', element: <CommandPopupStores /> }
          ]
        },
        {
          element: <RequireAuth />,
          children: [
            { path: '/prendies', element: <Prendies /> },
          ],
        }
      ]
    }
  ]);

  return routes;
}

export default Routes;
