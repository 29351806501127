import { Box } from '@mui/material';
import LoadingImg from 'assets/images/spinner.gif';
import DefaultImg from 'assets/icons/error.png';
import React, { useState } from 'react';
import { ImageProps } from 'types/image.interface';
import './styles.scss';

const Image = ({ src, alt }: ImageProps) => {
  const [isLoading, setIsLoading] = useState(true);

  function handleImageLoaded() {
    setIsLoading(false);
  }

  const imgUrl = src ?? DefaultImg;
  return (
    <>
      {isLoading &&
        <Box display="flex" alignItems="center" justifyContent="center" width={'100%'} height={'100%'}>
          <Box component="img" className='img-loading' alt={alt} width="50px !important" src={LoadingImg} />
        </Box>
      }
      <Box
        component="img"
        className="custom-img app-img"
        alt={alt}
        src={imgUrl}
        // loading="lazy"
        onLoad={handleImageLoaded}
        onError={(event: any) => {
          if (event.currentTarget.src.includes('https://prezemadmin.com'))
            return;
          event.currentTarget.src = require('assets/icons/error.png');
        }}
        style={{ display: isLoading ? 'none' : 'block' }}
      />

    </>
  );
};

export default React.memo(Image);
