import { ContentParams } from 'types/content.interface';
import api from './api';

const getContentList = (params: ContentParams) => {
  return api.get(
    `/api/v1/content?page=${params.page}&limit=25&order=ASC&keyword=${params.keyword}&type=${params.type}&orderField=id&isAnswer=${params.isAnswer}&isComment=${params.isComment}&isPost=${params.isPost}&isQuestion=${params.isQuestion}&isReported=${params.isReported}`
  );
};

const deleteContent = (id: number | null, type: string) => {
  return api.delete(`/api/v1/content/${id}`, { data: { type } });
};

const ContentService = {
  getContentList,
  deleteContent
};

export default ContentService;
