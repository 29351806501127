import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import TokenService from 'services/token.service';
import UserService from 'services/user.service';
import { User } from 'types/user.interface';

export const signIn = createAsyncThunk(
  'user/signIn',
  async (data: User, { rejectWithValue }) => {
    try {
      const res = await UserService.signIn(data);
      TokenService.setToken(res.data.data.accessToken);
      return res.data.data;
    } catch (err) {
      return rejectWithValue((err as AxiosError)?.response?.data);
    }
  }
);

export const getUserInfo = createAsyncThunk('user/getUserInfo',
  async (data, {rejectWithValue}) => {
    try {
      const res = await UserService.getUserInfo();
      return res?.data?.data?.user
    } catch (err) {
      return rejectWithValue((err as AxiosError)?.response?.data);
    }
  }
)
