import { EditPlaceBody, PlaceParams } from 'types/place.interface';
import api from './api';

const getPlaceList = (params: PlaceParams) => {
  return api.get(
    `/api/v1/location?page=${params.page}&limit=25&order=ASC&keyword=${params.keyword}&type=${params.type}&category=${params.category}&orderField=id`
  );
};

const getPlaceByID = (id: number | null) => {
  return api.get(
    `/api/v1/location/${id}`
  );
};

const updatePlace = (locationId: number | undefined, body: EditPlaceBody) => {
  return api.put(`/api/v1/location/${locationId}`, body);
};

const getLocationCategory = () => {
  return api.get(
    `/api/v1/location/category`
  );
};

const PlaceService = {
  getPlaceList,
  getPlaceByID,
  updatePlace,
  getLocationCategory
};

export default PlaceService;
