export const dialogInfo = {
    open: false,
    data: {}
}

export const dialogMessage = {
    open: false,
    message: ''
}

export const dialogResult = {
    open: false,
    title: '',
    message: '',
    btnText: '',
}