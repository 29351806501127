export enum UserStatus {
  ACTIVE = 'ACTIVE',
  SUSPENSION = 'SUSPENSION',
  DELETE = 'DELETE',
  DELETE_BY_MYSELF = 'DELETE_BY_MYSELF',
}

export function isActiveUser(status: UserStatus) {
  return status === UserStatus.ACTIVE;
}
