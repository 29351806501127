export const categories = [
  { value: 'locationNumber', label: '장소번호' },
  { value: 'addressUnit', label: '동' },
  { value: 'name', label: '장소명' },
  { value: 'address', label: '주소' },
  { value: 'registrationId', label: '등록한 아이디' }
];

export const regions = [
  { value: 'full', label: '전체' },
  { value: '서울', label: '서울' },
  { value: '경기', label: '경기' },
  { value: '대구', label: '대구' },
  { value: '부산', label: '부산' },
  { value: '인천', label: '인천' },
  { value: '대전', label: '대전' },
];

export const placeCategories = [
  // { value: 'restaurant', label: '음식점' },
  // { value: 'cafe', label: '카페' },
  // { value: 'stay', label: '숙박' },
  // { value: 'publicInstitution', label: '공공기관' },
  // { value: 'life', label: '생활/편의' },
  // { value: 'education', label: '교육' },
  // { value: 'culture', label: '문화' },
  // { value: 'other', label: '기타' },
  { value: '음식점', label: '음식점' },
  { value: '카페', label: '카페' },
  { value: '숙박', label: '숙박' },
  { value: '공공기관', label: '공공기관' },
  { value: '생활/편의', label: '생활/편의' },
  { value: '교육', label: '교육' },
  { value: '문화', label: '문화' },
  { value: '기타', label: '기타' },
];

export const places = {
  current_page: 1,
  total: 0,
  total_pages: 1,
  data: {
      locations: [],
      // totallLocationsSearched: 0,
      totalReportedLocations: 0
  }
}

export const placeHead = [
  { name: '장소번호' },
  { name: '동' },
  { name: '장소명' },
  { name: '주소' },
  { name: '등록한 아이디' },
  { name: '신고받은 수' },
  { name: '공유 수' },
  { name: '질문 수' },
  { name: '응답율' },
  { name: '게시글 수' }
]
