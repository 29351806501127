import api from './api';
import { UserStatus } from '../constants/users.constants';

export interface PrezemUser {
  id: number;
  created_at: string;
  status: UserStatus;
}

export async function FetchAllUsers(): Promise<PrezemUser[]> {
  try {
    const { data } = await api.get(`/api/admin/v2/users`);

    return data;
  } catch {
    return [];
  }
}
