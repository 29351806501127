import { NavigateFunction, NavLink, useNavigate } from 'react-router-dom';
import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { Badge, Collapse } from '@mui/material';
import List from '@mui/material/List';

interface MenusProps {
  title: string;
  path: string;
  icon: any;
  drawerOpen: boolean;
  subs?: {
    title: string;
    path: string;
    icon: any;
    showBadge?: boolean;
  }[];
}

export default function Menus({
  title,
  path,
  icon,
  drawerOpen,
  subs,
}: MenusProps) {
  const navigate: NavigateFunction = useNavigate();
  const hasShowBadge: boolean = !!(subs?.some(sub => sub.showBadge));

  const onClickListItemButton = (navigatePath: string): void => {
    if (!navigatePath) {
      return;
    }

    navigate(navigatePath);
  }

  return (
    <>
      <ListItemButton color="primary"
                      onClick={() => onClickListItemButton(path)}
                      sx={{
                        minHeight: 48,
                        justifyContent: drawerOpen ? 'initial' : 'center',
                        px: 2.5,
                        color: "white",
                      }}>
        <ListItemIcon sx={{
                        minWidth: 0,
                        mr: drawerOpen ? 3 : 'auto',
                        justifyContent: 'center'
                      }}>
          {!drawerOpen && hasShowBadge ? (
            <Badge color="error"
                   variant="dot">
              {icon}
            </Badge>
          ) : (icon)}
        </ListItemIcon>
        <ListItemText sx={{ opacity: drawerOpen ? 1 : 0 }}>
          <NavLink to={'/member-information'}
                   className='sidebar-link'>
            <Badge color="error"
                   variant="dot"
                   invisible={!hasShowBadge}>
              {title}
            </Badge>
          </NavLink>
        </ListItemText>
      </ListItemButton>

      {drawerOpen && subs && subs.length > 0 && subs.map(sub => (
        <Collapse key={sub.path}
                  in
                  timeout="auto"
                  unmountOnExit >
          <List component="div"
                disablePadding>
            <ListItemButton onClick={() => onClickListItemButton(sub.path)}
                            sx={{ pl: 5 }}>
              <ListItemIcon>
                {sub.icon}
              </ListItemIcon>
              <ListItemText sx={{ opacity: drawerOpen ? 1 : 0, ml: -2 }}>
                <NavLink to={sub.path}
                         className='sidebar-link'>
                  <Badge color="error"
                         variant="dot"
                         invisible={!sub.showBadge}>
                    {sub.title}
                  </Badge>
                </NavLink>
              </ListItemText>
            </ListItemButton>
          </List>
        </Collapse>
      ))}
    </>
  );
}
