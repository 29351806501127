import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Pagination,
  Select,
  TextField
} from '@mui/material';
import DialogConfirm from 'dialog/DialogConfirm';
import ButtonCustom from 'elements/Button';
import TabelComponent from 'elements/TableCell';
import { SetStateAction, useEffect, useState } from 'react';
import ContentService from 'services/content.service';
import { CheckBoxDefault } from 'types/checkbox.interface';
import { Content, ContentPagination, UserTags } from 'types/content.interface';
import {
  categories,
  contentData,
  contentHead,
  scopesSearch
} from 'types/entities/content';
import './styles.scss';

const ContentManagement = () => {
  const [category, setCategory] = useState<string>('contentsNumber');
  const [check, setCheck] = useState<CheckBoxDefault[]>(scopesSearch);
  const [keySearch, setKeySearch] = useState<string>('');
  const [contentList, setContentList] = useState<Content[]>([]);
  const [data, setData] = useState<ContentPagination>(contentData);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<{
    open: boolean;
    id: number | null;
    type: string;
  }>({ open: false, id: null, type: '' });

  const handleCategoryChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setCategory(event.target.value);
  };
  const handleInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setKeySearch(event.target.value);
  };
  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const curVal: CheckBoxDefault[] = [...check];
    const newVal: CheckBoxDefault[] = curVal.map((val: CheckBoxDefault) => {
      if (val.name === event.target.name) {
        val.value = event.target.checked;
      }
      return val;
    });
    setCheck(newVal);
  };

  const handlePaginationChange = (_: {}, p: number) => {
    setData((prevState: ContentPagination) => {
      return { ...prevState, current_page: p };
    });
  };

  const handleSearch = () => {
    if (data.current_page !== 1) {
      setData((prevState: ContentPagination) => {
        return { ...prevState, current_page: 1 };
      });
    } else {
      getContentList();
    }
  };

  const getCommentTags = (s: string, tags: UserTags[]) => {
    let result = s;
    if (tags && tags.length) {
      tags.forEach((tag) => {
        if (tag?.user?.alias) {
          result = result.replace(
            `@{userId:${tag?.userId}}`,
            '@' + tag?.user?.alias
          );
        }
      });
    }
    return result;
  };

  const getContentList = async () => {
    const response = await ContentService.getContentList({
      page: data?.current_page || 1,
      type: category,
      keyword: keySearch || '',
      isPost: check[0].value || '',
      isComment: check[1].value || '',
      isQuestion: check[2].value || '',
      isAnswer: check[3].value || '',
      isReported: check[4].value || ''
    });
    const { total_pages, current_page } = response?.data?.meta?.pagination;
    if (total_pages && current_page > total_pages) {
      setData((prevState: ContentPagination) => {
        return { ...prevState, current_page: 1 };
      });
    } else {
      setData((prevState: ContentPagination) => {
        return {
          ...prevState,
          total_pages: response?.data?.meta?.pagination?.total_pages,
          total: response?.data?.meta?.pagination?.total,
          data: response?.data?.data
        };
      });
      const bodyTable = response?.data?.data?.contents.map((item: Content) => {
        return {
          uniqueNumber: item.uniqueNumber,
          author: item.author,
          location: item.location,
          content: item.isComment
            ? getCommentTags(item.content ?? '', item.listUsersTags)
            : item.content,
          type: item.type,
          reportsNum: item.reportsNum,
          imagePreview: item?.photos ? item?.photos : item?.photo,
          delete: '삭제'
        };
      }) as [];
      setContentList(bodyTable);
    }
  };

  useEffect(() => {
    getContentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.current_page, check]);

  const deleteContent = (index: number) => {
    setOpenDeleteDialog({
      open: true,
      type: data?.data?.contents[index]?.type,
      id: data?.data?.contents[index]?.id
    });
  };

  const handleDelete = async () => {
    const res = await ContentService.deleteContent(
      openDeleteDialog.id,
      openDeleteDialog.type
    );
    if (res) {
      setOpenDeleteDialog({ open: false, id: null, type: '' });
      getContentList();
    }
  };

  return (
    <div className="main-content content-management">
      <h4 className="number-reported">
        신고 받은 콘텐츠 수 : {data?.data?.totalReportNum}
      </h4>
      <div className="content-management-content">
        <div className="section-search">
          {/* Checkbox group */}
          <Box className="checkbox-group">
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormGroup>
                {check && check.length
                  ? check.map((item: CheckBoxDefault, index: number) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={item.value}
                            onChange={(e) => handleCheck(e)}
                            name={item.name}
                            sx={{
                              color: '#FF6151',
                              '&.Mui-checked': {
                                color: '#FF6151'
                              }
                            }}
                          />
                        }
                        label={item.label}
                      />
                    ))
                  : null}
              </FormGroup>
            </FormControl>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <div className="search-contain">
                <FormControl sx={{ m: 1 }} className="selection">
                  <Select
                    value={category}
                    onChange={handleCategoryChange}
                    displayEmpty
                  >
                    {categories && categories.length
                      ? categories.map((item) => (
                          <MenuItem
                            key={item.value}
                            className="menu-item"
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        ))
                      : ''}
                  </Select>
                </FormControl>
                <TextField
                  className="input-search"
                  value={keySearch}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter' || e.code === 'NumpadEnter')
                      handleSearch();
                  }}
                />
                <Box className="search-button">
                  <ButtonCustom
                    title="검색"
                    cate="standard"
                    onClick={handleSearch}
                  />
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} md={2}>
              <span className="number-searched">(총 : {data?.total}건)</span>
            </Grid>
          </Grid>
        </div>
        <div className="section-content-list">
          <TabelComponent
            dataHead={contentHead}
            databody={contentList}
            actionDelete={deleteContent}
          />
          <DialogConfirm
            title="정말 삭제하시겠습니까?"
            btnText="확인"
            open={openDeleteDialog.open}
            handleOk={handleDelete}
            handleClose={() =>
              setOpenDeleteDialog({ open: false, id: null, type: '' })
            }
          />
        </div>
        <Pagination
          className="section-pagination"
          count={data?.total_pages || 1}
          page={data?.current_page || 1}
          onChange={handlePaginationChange}
          variant="outlined"
        />
      </div>
    </div>
  );
};

export default ContentManagement;
