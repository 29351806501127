import { RegisterAdmin } from 'types/admin.interface';
import api from './api';

const getAdminList = (page: number) => {
  return api.get(
    `/api/v1/user/admins?page=${page}&limit=25&order=ASC&orderField=id`
  );
};

const deleteAdmin = (id: number | null) => {
  return api.put(`/api/v1/user/admin/delete/${id}`);
};

const registerAdmin = (body: RegisterAdmin) => {
  return api.post(`/api/v1/user/admin/register`, body);
};

const updateAdmin = (alias: string, password: string) => {
  return api.put(`/api/v1/user/admin/changePassword`, { password, alias });
};

const AdminService = {
  getAdminList,
  deleteAdmin,
  updateAdmin,
  registerAdmin
};

export default AdminService;
