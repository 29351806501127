import '../styles.scss';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import {
  DialogResultProps,
  DialogProps,
  DialogTitleProps
} from 'types/dialog.interface';
import { useEffect, useState } from 'react';
import DialogConfirmClose from 'dialog/DialogConfirmClose';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Grid, IconButton } from '@mui/material';
import Input from 'elements/input';
import EyeIcon from 'assets/icons/eye';
import EyeCrossIcon from 'assets/icons/eye-cross';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Admin } from 'types/admin.interface';
import AdminService from 'services/admin.service';
import DialogSuccess from 'dialog/DialogSuccess';
import DialogResult from 'dialog/DialogResult';
import { dialogMessage } from 'types/entities/dialog';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const AddAdminDialog = ({ open, onClose, refresh, id }: DialogProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [openDialogX, setOpenDialogX] = useState<boolean>(false);
  const [openDialogError, setOpenDialogError] =
    useState<DialogResultProps>(dialogMessage);
  const [openDialogSuccess, setOpenDialogSuccess] =
    useState<DialogResultProps>(dialogMessage);

  const handleCloseDialogX = () => {
    setOpenDialogX(false);
  };

  const handleSuccess = () => {
    setOpenDialogSuccess({ open: false, message: '' });
    onClose();
  };
  const validationSchema = Yup.object().shape({
    alias: Yup.string().required('아이디를 입력해주세요'),
    password: Yup.string()
      .required('비밀번호 입력')
      .matches(
        /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.{6,})/,
        '영문,숫자 조합으로 6자리 이상 입력해주세요'
      ),
    confirmPassword: Yup.string()
      .required('비밀번호 확인')
      .oneOf([Yup.ref('password'), null], '비밀번호가 일치하지 않습니다.')
  });

  const defaultValues = {
    alias: '',
    password: '',
    confirmPassword: ''
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues
  };
  const { handleSubmit, formState, control, reset, trigger } = useForm<Admin>({
    mode: 'onChange',
    ...formOptions
  });

  const { errors } = formState;

  const handleClickShowPassword = () => {
    setShowPassword((s) => !s);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((s) => !s);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const EndComponent = (onClick: () => void, isShow: boolean) => {
    return (
      <IconButton
        aria-label="toggle password visibility"
        onClick={onClick}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {isShow ? <EyeIcon /> : <EyeCrossIcon />}
      </IconButton>
    );
  };

  const { alias, password, confirmPassword } = errors;

  const onSubmit: SubmitHandler<Admin> = (form) => {
    if (id) {
      updateAdmin(form);
    } else {
      addAdmin(form);
    }
  };

  const addAdmin = async (form: Admin) => {
    const { alias, password } = form;
    try {
      const response: any = await AdminService.registerAdmin({
        alias,
        password
      });
      if (response) {
        refresh();
        setOpenDialogSuccess({ open: true, message: '회원가입 완료' });
      }
    } catch (error: any) {
      const message =
        error?.response?.data?.meta?.messages[0]?.message ?? 'Error';
      setOpenDialogError({
        open: true,
        message
      });
    }
  };

  const updateAdmin = async (form: Admin) => {
    const { alias, password } = form;
    const response: any = await AdminService.updateAdmin(alias, password);
    if (response) {
      refresh();
      setOpenDialogSuccess({ open: true, message: '수정 완료' });
    }
  };

  const handleCloseDialog = (event: DialogTitleProps, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  useEffect(() => {
    reset({ alias: id ?? '', password: '', confirmPassword: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseDialog}
        open={open}
        className="add-admin-dialog"
      >
        <BootstrapDialogTitle
          id="add-admin-dialog"
          onClose={() => (formState.isDirty ? setOpenDialogX(true) : onClose())}
        >
          {id ? '계정 수정' : '계정 추가'}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form
            id="add-admin-form"
            className="add-admin-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} className="input-item">
                <p className="label">관리자 아이디</p>
                <Input
                  type="text"
                  name="alias"
                  trigger={trigger}
                  triggerName="alias"
                  control={control}
                  error={!!alias}
                  helperText={alias && alias.message}
                  start={() => <PersonOutlineOutlinedIcon />}
                  placeholder="아이디를 입력해주세요"
                  disabled={!!id}
                />
              </Grid>
              <Grid item xs={12} sm={12} className="input-item">
                <p className="label">관리자 비빌번호</p>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  trigger={trigger}
                  triggerName={['password', 'confirmPassword']}
                  control={control}
                  error={!!password}
                  helperText={password && password.message}
                  start={() => <LockOutlinedIcon />}
                  end={() =>
                    EndComponent(handleClickShowPassword, showPassword)
                  }
                  placeholder="비밀번호 입력"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12} sm={12} className="input-item">
                <Input
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  trigger={trigger}
                  triggerName={['password', 'confirmPassword']}
                  control={control}
                  error={!!confirmPassword}
                  success={
                    formState.dirtyFields.confirmPassword && !confirmPassword
                  }
                  successText="비밀번호가 일치합니다"
                  helperText={confirmPassword && confirmPassword.message}
                  start={() => <LockOutlinedIcon />}
                  end={() =>
                    EndComponent(
                      handleClickShowConfirmPassword,
                      showConfirmPassword
                    )
                  }
                  placeholder="비밀번호 확인"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            form="add-admin-form"
            className="save-button"
            autoFocus
            type="submit"
            disabled={
              !formState.isDirty || !!alias || !!password || !!confirmPassword
            }
          >
            {id ? '계정 수정하기' : '계정 추가하기'}
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <DialogConfirmClose
        open={openDialogX}
        handleOk={onClose}
        handleClose={handleCloseDialogX}
      />
      <DialogResult
        message={openDialogError.message}
        open={openDialogError.open}
        btnText="학인"
        handleOk={() => setOpenDialogError({ open: false, message: '' })}
        handleClose={() => setOpenDialogError({ open: false, message: '' })}
      />
      <DialogSuccess
        message={openDialogSuccess.message}
        open={openDialogSuccess.open}
        handleClose={handleSuccess}
      />
    </>
  );
};

export default AddAdminDialog;
