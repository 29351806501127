import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { CSSObject, Theme, styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import TokenService from 'services/token.service';
import { titleConfig } from './sidebarConfig';
import './styles.scss';
import PersonIcon from '@material-ui/icons/Person';
import Menus from './components/Menus';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ReportIcon from '@mui/icons-material/Report';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import PublicIcon from '@material-ui/icons/Public';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import StoreIcon from '@mui/icons-material/Store';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { ApplicationPrendy, FetchAllApplicationPrendies } from '../../../services/prendies.service';
import { PrendyApplicationStatus } from '../../../constants/prendy.constants';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  backgroundColor: '#000',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export default function V2SideBar() {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const navigate = useNavigate();
  const [hasUnproceedPrendyApplication, setHasUnproceedPrendyApplication] = React.useState(false);

  useEffect(() => {
    async function fetchApplicationPrendy() {
      const applicationPrendies: ApplicationPrendy[] = await FetchAllApplicationPrendies();
      const applications = applicationPrendies.filter((applicationPrendy: ApplicationPrendy) => applicationPrendy.status === PrendyApplicationStatus.APPLICATION)
      setHasUnproceedPrendyApplication(applications.length > 0);
    }

    fetchApplicationPrendy();
  }, []);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => setAnchorEl(event.currentTarget);

  const handleClose = (): void => setAnchorEl(null);

  useEffect(() => {
    const title = titleConfig[location.pathname];

    setTitle(title || '');
  }, [location.pathname]);

  const logout = () => {
    TokenService.removeUser();
    navigate('/login');
  };

  const handleDrawerOpen = (): void => setOpen(true);
  const handleDrawerClose = (): void => setOpen(false);

  return (
    <Box className="sidebar"
         sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed"
              open={open}>
        <Toolbar>
          <Typography className={open ? 'header-open' : 'header-close'}
                      variant="h6"
                      noWrap
                      component="div">
            {title}
          </Typography>

          <div style={{ position: 'absolute', bottom: 0, right: 20 }}>
            <IconButton size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit">
              <Box
                component="img"
                className="logout-speed-dial"
                alt="Logout"
                src={require('assets/icons/prezem-icon.png')}
              />
            </IconButton>
            <Menu id="appbar-menu"
                  sx={{ mt: '45px', mr: '5px' }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}>
              <MenuItem onClick={() => {
                setAnchorEl(null);
                navigate('/dashboards');
              }}>Dashboard</MenuItem>
              <MenuItem onClick={logout}>
                <Typography color="error">Logout</Typography>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer className="sidebar-left"
              variant="permanent"
              open={open}>
        <DrawerHeader>
          {open ? (
            <>
              <div className="logo-container" onClick={() => navigate('/')}>
                <Box
                  component="img"
                  className="logo-img"
                  alt="Prezem logo"
                  src={require('assets/icons/PREZEM_LOGO.png')}
                />
                <Box
                  component="img"
                  className="logo-typo"
                  alt="Prezem text"
                  src={require('assets/images/PREZEM_LOGO_LOGIN.png')}
                />
              </div>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </>
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className="icon-button"
              sx={{
                marginRight: 5,
                ...(open ? { display: 'none' } : {})
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />

        <List>
          <ListItem key={0}
                    disablePadding
                    sx={{ display: 'block' }} >

            <Menus key="menu-member-information"
                   title="회원 관리"
                   path="/member-information"
                   icon={<PersonIcon />}
                   drawerOpen={open}
                   subs={[
                     {
                       title: '프렌디 신청목록',
                       icon: <SensorOccupiedIcon />,
                       path: '/prendies',
                       showBadge: hasUnproceedPrendyApplication
                     },
                     {
                       title: '프모지 관리',
                       icon: <EmojiEmotionsIcon />,
                       path: '/pmoji-management',
                     },
                     {
                       title: '설탕 관리',
                       icon: <LoyaltyIcon />,
                       path: 'users/sugars',
                     },
                     {
                       title: '신고 관리 (WIP)',
                       icon: <ReportIcon />,
                       path: 'users/reports',
                     },
                   ]} />

          </ListItem>
          <ListItem key={1}
                    disablePadding
                    sx={{ display: 'block' }} >
            <Menus title="장소 관리"
                   path="/place-management"
                   icon={<PublicIcon />}
                   drawerOpen={open}
                   subs={[]} />
          </ListItem>

          <ListItem key={2}
                    disablePadding
                    sx={{ display: 'block' }} >
            <Menus key="menu-content-management"
                   title="콘텐츠 관리"
                   path="/content-management"
                   icon={<CallToActionIcon />}
                   drawerOpen={open}
                   subs={[]}
            />
          </ListItem>

          <ListItem key={3}
                    disablePadding
                    sx={{ display: 'block' }} >
            <Menus key="menu-popup-stores"
                   title="팝업 스토어 관리"
                   path="/popup-stores"
                   icon={<StoreIcon />}
                   drawerOpen={open}
                   subs={[
                     {
                       title: '팝업 스토어 생성',
                       icon: <AddBusinessIcon />,
                       path: '/popup-stores/create',
                     },
                     {
                       title: '오픈 예정 알림신청 목록',
                       icon: <NotificationImportantIcon />,
                       path: '/popup-stores/open-notification-application',
                     }
                   ]}
            />
          </ListItem>

          <ListItem key={4}
                    disablePadding
                    sx={{ display: 'block' }} >
            <Menus key="menu-admin-account"
                   title="관리자 계정"
                   path="/admin-account"
                   icon={<SupervisorAccountIcon />}
                   drawerOpen={open}
                   subs={[]}
            />
          </ListItem>
        </List>
      </Drawer>
      <Box className="main-layout"
           component="main"
           sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <main>
          <Outlet />
        </main>
      </Box>
    </Box>
  );
}
