export const sugarHead = [
  { name: '회원번호' },
  { name: '고유 아이디' },
  { name: '아이디' },
  { name: '닉네임' },
  { name: '휴대폰번호' },
  { name: '현재 설탕 수' },
  { name: '누적 설탕 수' },
  { name: '설탕 관리' }
];

export const sugarHeadDetail = [
  { name: '날짜' },
  { name: '변동사유' },
  { name: '변동내역' },
  { name: '잔여 설탕 수' }
];

export const sugarData = {
  current_page: 1,
  total_pages: 1,
  total: 0,
  data: {
    items: [],
    totalReportNum: 0,
  }
};

export interface SugarPagination {
  current_page: number;
  total_pages: number;
  total: number;
  data: any;
}
