import { Box, Card, CardContent, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

export interface DashboardCardProps {
  title: string;
  mainValue: string;
  mainValueComponent?: any;
  description: string;
  link?: string;
  isLoaded?: boolean;
}

export default function DashboardCard({
  title,
  mainValue,
  mainValueComponent,
  description,
  link,
  isLoaded = false,
}: DashboardCardProps) {
  const navigate = useNavigate();

  const onClickMainValue = () => {
    if (!link) {
      return;
    }

    navigate(link);
  }

  return (
    <Card sx={{ height: 120, overflowY: 'none' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {title}
        </Typography>
        {isLoaded ? (
          <Box>
            <Typography variant="h5"
                        component="div"
                        onClick={onClickMainValue}
                        sx={{
                          cursor: link ? 'pointer' : 'default',
                          textDecoration: link ? 'underline' : 'none',
                        }}
            >
              {mainValue}
            </Typography>
            {mainValueComponent}
            <Typography sx={{ mb: 1.5, fontSize: 10 }} color="text.secondary">
              {description}
            </Typography>
          </Box>
        ) : (
          <CircularProgress sx={{ mt: 1 }}/>
        )}
      </CardContent>
    </Card>
  );
}