import React from 'react';
import { Box, InputAdornment, InputLabel, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import './styles.scss';

interface input {
  type: string;
  label?: string;
  control: any;
  name: string;
  start?: any;
  end?: any;
  element?: any;
  placeholder?: string;
  error?: boolean;
  isDirty?: boolean;
  helperText?: any;
  success?: boolean;
  successText?: string;
  trigger?: any;
  triggerName?: string | string[];
  disabled?: boolean;
  autoComplete?: string;
}

const Input = ({
  type,
  label,
  control,
  name,
  start,
  end,
  element,
  error,
  isDirty,
  placeholder,
  helperText,
  success,
  successText,
  trigger,
  triggerName,
  disabled,
  autoComplete
}: input) => {
  return (
    <Box className="input_wrapper">
      {label && (
        <InputLabel htmlFor={name} color="info" className="label">
          {label}
        </InputLabel>
      )}
      <Box className="container">
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              id={name}
              type={type}
              onChange={onChange}
              onFocus={() => trigger(triggerName)}
              value={value}
              className={'input' + (success ? ' success' : '')}
              placeholder={placeholder}
              error={error}
              focused={!error && isDirty}
              fullWidth
              InputProps={{
                startAdornment: start && (
                  <InputAdornment position="start">{start()}</InputAdornment>
                ),
                endAdornment: end && (
                  <InputAdornment position="end">{end()}</InputAdornment>
                ),
                autoComplete: autoComplete,
              }}
              helperText={helperText}
              disabled={disabled}
            />
          )}
        />
        {element && element()}
      </Box>
      {success && <p className="success-helper-text">{successText}</p>}
    </Box>
  );
};

export default Input;
