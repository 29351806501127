import { store } from 'app/store';
import axios from 'axios';
import { setLoading } from 'components/Loading/loadingSlice';
import TokenService from './token.service';

export interface CoreResponse {
  statusCode: number;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API ?? 'https://api.prezemadmin.com',
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(
  (config) => {
    store.dispatch(setLoading(true));
    const token = TokenService.getToken();
    if (token) {
      config.headers!['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    store.dispatch(setLoading(false));
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    store.dispatch(setLoading(false));
    return res;
  },
  async (err) => {
    store.dispatch(setLoading(false));

    if (err.response?.data?.meta?.code === 119) {
      if (TokenService.getToken()) {
        TokenService.removeUser();
        window.location.href = '/login';
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
