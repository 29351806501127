import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Swal from 'sweetalert2'

import {
  ApproveOrRejectApplicationPrendy,
  FetchAllApplicationPrendies,
  RollbackApplicationPrendy,
  switchPrendyName
} from '../../services/prendies.service';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import PrendyApplicationApproveRejectModal from '../../modals/PrendyApplicationApproveRejectModal';
import { PrendyApplicationStatus, switchApplicationStatus } from '../../constants/prendy.constants';

dayjs.locale('ko');

const usePrendies = () => {
  const [isAlreadyProcessed, setIsAlreadyProcessed] = useState<boolean>(false);
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'userId',
      headerName: '유저 ID',
      minWidth: 100,
    },
    {
      field: 'applicationPrendy',
      headerName: '신청 Prendy',
      minWidth: 100,
    },
    {
      field: 'status',
      headerName: '신청 상태',
      minWidth: 100,
    },
    {
      field: 'applicationAt',
      headerName: '신청일',
      minWidth: 200,
    },
    {
      field: 'postCount',
      headerName: '게시물 수',
      minWidth: 100,
    },
    {
      field: 'followersCount',
      headerName: '팔로워 수',
      minWidth: 100,
    },
    {
      field: 'actions',
      headerName: '승인/거부',
      minWidth: 100,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setSelectedApplication(params.row);
            setModalOpen(true);
          }}
        >승인/거부</Button>
      ),
    }
  ]);
  const [applicationPrendies, setApplicationPrendies] = useState<any[]>([]);
  const [allPrendies, setAllPrendies] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedApplication, setSelectedApplication] = useState<any | null>(null);

  const onModalClose = () => {
    setModalOpen(false);
    setSelectedApplication(null);
  }

  const onApproveOrReject = async (isApprove: boolean, rejectReason?: string) => {
    const approveMessage = isApprove ? '승인' : '거절';

    try {
      const isSuccess = await ApproveOrRejectApplicationPrendy(selectedApplication.id, isApprove, rejectReason);

      setModalOpen(false);
      if (!isSuccess) {
        await Swal.fire('오류 발생', `${approveMessage}에 실패했습니다.`, 'error');
        window.location.reload();
        return;
      }

      await Swal.fire('성공', `${approveMessage}이 완료되었습니다.`, 'success');
      window.location.reload();
    } catch {
      setModalOpen(false);
      await Swal.fire('오류 발생', `${approveMessage} 시 오류가 발생했습니다.`, 'error');
      window.location.reload();
      return;
    }
  };

  useEffect(() => {
    async function fetchAllPrendies() {
      const fetchedPrendies = await FetchAllApplicationPrendies();

      setAllPrendies(fetchedPrendies);

      setApplicationPrendies(fetchedPrendies.filter((applicationPrendy: any) => applicationPrendy.status === PrendyApplicationStatus.APPLICATION));
    }

    fetchAllPrendies();
  }, []);

  useEffect(() => {
    const newRows = isAlreadyProcessed ? allPrendies.filter((prendy) => prendy.status !== PrendyApplicationStatus.APPLICATION).map((applicationPrendy: any) => ({
      id: applicationPrendy.id,
        userId: applicationPrendy.user.alias,
        status: switchApplicationStatus(applicationPrendy.status),
        applicationPrendy: switchPrendyName(applicationPrendy.applicationPrendy),
        applicationAt: dayjs(applicationPrendy.createdAt).format('YYYY-MM-DD HH:mm'),
        postCount: applicationPrendy.user.postCount,
        followersCount: applicationPrendy.user.followersCount,
    })) : applicationPrendies.map((applicationPrendy: any) => ({
      id: applicationPrendy.id,
      userId: applicationPrendy.user.alias,
      status: switchApplicationStatus(applicationPrendy.status),
      applicationPrendy: switchPrendyName(applicationPrendy.applicationPrendy),
      applicationAt: dayjs(applicationPrendy.createdAt).format('YYYY-MM-DD HH:mm'),
      postCount: applicationPrendy.user.postCount,
      followersCount: applicationPrendy.user.followersCount,
    }));

    setRows(newRows);
  }, [allPrendies, applicationPrendies, isAlreadyProcessed]);

  useEffect(() => {
    setColumns([
      {
        field: 'userId',
        headerName: '유저 ID',
        minWidth: 100,
      },
      {
        field: 'applicationPrendy',
        headerName: '신청 Prendy',
        minWidth: 100,
      },
      {
        field: 'status',
        headerName: '신청 상태',
        minWidth: 100,
      },
      {
        field: 'applicationAt',
        headerName: '신청일',
        minWidth: 200,
      },
      {
        field: 'postCount',
        headerName: '게시물 수',
        minWidth: 100,
      },
      {
        field: 'followersCount',
        headerName: '팔로워 수',
        minWidth: 100,
      },
      {
        field: 'actions',
        headerName: isAlreadyProcessed ? '복구하기' : '승인/거부',
        minWidth: 100,
        renderCell: (params) => (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              if (isAlreadyProcessed) {
                Swal.fire({
                  title: "복구 작업",
                  text: "복구 작업을 진행하시겠습니까?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "네",
                  cancelButtonText: "아니요",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    const isSuccess = await RollbackApplicationPrendy(params.row.id);

                    if (!isSuccess) {
                      await Swal.fire('오류 발생', '복구에 실패했습니다.', 'error');
                      return;
                    }

                    await Swal.fire('성공', '복구가 완료되었습니다.', 'success');
                    window.location.reload();
                  }
                });
              } else {
                setSelectedApplication(params.row);
                setModalOpen(true);
              }
            }}
          >{isAlreadyProcessed ? '복구하기' : '승인/거부'}</Button>
        ),
      }
    ]);
  }, [isAlreadyProcessed]);

  return {
    models: {
      columns,
      rows,
      modalOpen,
      selectedApplication,
      isAlreadyProcessed,
    },
    operations: {
      onModalClose,
      onApproveOrReject,
      setIsAlreadyProcessed,
    },
  }
}

export default function Prendies() {
  const { models, operations } = usePrendies();
  return (
    <div className="main-content">
      <div>
        <Grid container>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="기처리된 Prendy보기"
                checked={models.isAlreadyProcessed}
                onChange={(e, checked) => operations.setIsAlreadyProcessed(checked)}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          height: '80vh',
          width: '100%',
        }}
      >
        <DataGrid
          columns={models.columns}
          rows={models.rows}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[5, 10]}
          />

        <PrendyApplicationApproveRejectModal
          open={models.modalOpen}
          onClose={operations.onModalClose}
          userId={models.selectedApplication?.userId}
          applicationPrendy={models.selectedApplication?.applicationPrendy}
          applicationAt={models.selectedApplication?.applicationAt}
          postCount={models.selectedApplication?.postCount}
          followersCount={models.selectedApplication?.followersCount}
          onClickApproveOrReject={operations.onApproveOrReject}
        />
      </div>
    </div>
  )
}
