const setToken = (token: string) => {
  localStorage.setItem('token', JSON.stringify(token));
};

const getToken = () => {
  return JSON.parse(localStorage.getItem('token')!);
};

const removeUser = () => {
  localStorage.removeItem('token');
};

const TokenService = {
  getToken,
  setToken,
  removeUser
};

export default TokenService;
