import {MemberDetailDialogProps } from 'types/member.interface';
import '../styles.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { DialogResultProps, DialogTitleProps } from 'types/dialog.interface';
import { dateFormat } from 'utils/date';
import MemberService from 'services/member.service';
import { MEMBER_STATUS } from 'constants/status';
import DialogConfirmClose from 'dialog/DialogConfirmClose';
import { dialogMessage } from 'types/entities/dialog';
import DialogSuccess from 'dialog/DialogSuccess';

interface MemberForm {
  comment: string;
  status: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const MemberDetailDialog = ({
  open,
  data,
  handleClose,
  handleRefresh
}: MemberDetailDialogProps) => {
  const [openDialogX, setOpenDialogX] = useState<boolean>(false);
  const [openDialogSuccess, setOpenDialogSuccess] =
    useState<DialogResultProps>(dialogMessage);

  useEffect(() => {
    reset({
      comment: data?.comment ?? '',
      status: data?.status === MEMBER_STATUS.SUSPENSION
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const validationSchema = Yup.object().shape({
    comment: Yup.string().test('len', 'Comments for members that are limited to 500 characters.', (val) => {
      if (val === undefined) {
        return true;
      }
      return val.length <= 500;
    }),
    status: Yup.boolean()
  });

  const defaultValues = {
    comment: '',
    status: false
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues
  };
  const { handleSubmit, formState, control, reset } =
    useForm<MemberForm>(formOptions);
  // const { errors } = formState;
  // const { comment } = errors;

  const onSubmit: SubmitHandler<MemberForm> = (form) => {
    save(form);
  };
    
  const handleCloseDialogX = () => {
    setOpenDialogX(false);
  };

  const handleSuccess = () => {
    setOpenDialogSuccess({ open: false, message: '' });
    handleClose();
  };

  const save = async (form: MemberForm) => {
    const {comment, status} = form;
    const response: any = await MemberService.updateMember({
      userId: data?.id,
      comment,
      status: status ? MEMBER_STATUS.SUSPENSION : MEMBER_STATUS.ACTIVE
    });
    if (response) {
      handleRefresh();
      // handleClose();
      setOpenDialogSuccess({ open: true, message: '저장되었습니다' });
    }
  };

  const handleCloseDialog = (event: DialogTitleProps, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseDialog}
        open={open}
        className="member-list-dialog"
      >
        <BootstrapDialogTitle
          id="member-list-dialog"
          onClose={() => formState.isDirty ? setOpenDialogX(true) : handleClose()}
        >
          회원 관리
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form>
            <div className="info-item">
              <p className="label">회원 번호</p>
              <p className="value">{data?.id}</p>
            </div>
            <div className="info-item">
              <p className="label">휴대폰 번호</p>
              <p className="value">{data?.phone}</p>
            </div>
            <div className="info-item">
              <p className="label">활동 아이디</p>
              <p className="value">{data?.alias}</p>
            </div>
            <div className="info-item">
              <p className="label">가입 날짜</p>
              <p className="value">
                {dateFormat(data?.created_at, 'YY.MM.DD HH:mm')}
              </p>
            </div>
            <div className="info-item">
              <p className="label">신고받은 수</p>
              <p className="value">{data?.reportsNum}</p>
            </div>
            <div className="section-comment">
              <p className="label">관리자 코멘트</p>
              <Controller
                name="comment"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextareaAutosize
                    onChange={onChange}
                    value={value}
                    maxRows={8}
                    minRows={8}
                    maxLength={500}
                    placeholder="내용을 입력하세요."
                  />
                )}
              />
            </div>
            <div className="info-item">
              <p className="label">계정 활성 여부</p>
              <p className="value">{data?.status === MEMBER_STATUS.SUSPENSION ? '비활성' : '활성'}</p>
            </div>
            <div className="info-item">
              <p className="label">정지 설정</p>
              <div className="value checkbox-custom">
                <span>정지</span>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      inputProps={{ 'aria-label': 'controlled' }}
                      onChange={onChange}
                      checked={value}
                      sx={{
                        color: '#FF6151',
                        '&.Mui-checked': {
                          color: '#FF6151'
                        }
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className="save-button"
            autoFocus
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isDirty}
          >
            저장하기
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <DialogConfirmClose
        open={openDialogX}
        handleOk={handleClose}
        handleClose={handleCloseDialogX}
      />
      <DialogSuccess
        message={openDialogSuccess.message}
        open={openDialogSuccess.open}
        handleClose={handleSuccess}
      />
    </>
  );
};

export default MemberDetailDialog;
