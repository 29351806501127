import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import V2SideBar from '../elements/v2/SideBar/SideBar';

function Layout() {
  const location = useLocation();
  return (
    <>
      {location?.pathname === '/login' ? (
        <div>
          {/* <Header /> */}
          <main>
            <Outlet />
          </main>
          {/* <Footer /> */}
        </div>
      ) : (
        <div>
          <V2SideBar />
          {/* <Footer /> */}
        </div>
      )}
    </>
  );
}

export default Layout;
