export const categories = [
  { value: 'author', label: '작성자 아이디' },
  { value: 'contentsNumber', label: '콘텐츠 번호' },
  { value: 'place', label: '태그된 장소	' },
  { value: 'content', label: '내용' }
];

export enum SearchCategorySugarValues {
  memberShipNumber = 'memberShipNumber',
  uniqueId = 'uniqueId',
  alias = 'alias',
  nickName = 'nickName',
  phoneNumber = 'phoneNumber',
}

export const searchOptionSugar = [
  { value: 'phoneNumber', label: '휴대폰번호' },
  { value: 'memberShipNumber', label: '회원번호' },
  { value: 'uniqueId', label: '고유 아이디' },
  { value: 'alias', label: '아이디' },
  { value: 'nickName', label: '닉네임' },
];

export const contentData = {
  current_page: 1,
  total_pages: 1,
  total: 0,
  data: {
    contents: [],
    totalReportNum: 0
  }
};

export const scopesSearch = [
  {
    value: true,
    name: 'post',
    label: '게시물'
  },
  {
    value: true,
    name: 'comment',
    label: '댓글'
  },
  {
    value: true,
    name: 'question',
    label: '질문'
  },
  {
    value: true,
    name: 'answer',
    label: '답변'
  },
  {
    value: true,
    name: 'reported',
    label: '신고 받은 게시물'
  }
];

export const contentHead = [
  { name: '콘텐츠 번호' },
  { name: '작성자 아이디' },
  { name: '태그된 장소' },
  { name: '내용' },
  { name: '형태' },
  { name: '신고받은 수' },
  { name: '콘텐츠 사진' },
  { name: '강제 삭제' }
];
