import { Navigate, Outlet } from 'react-router-dom';
import TokenService from 'services/token.service';

function RequireAuth() {
  const token = TokenService.getToken();

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
}

export default RequireAuth;
