import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ADMIN_ROLE, MEMBER_STATUS } from 'constants/status';
import ButtonCustom from 'elements/Button';
import Image from 'elements/Image';
import PreviewImage from 'elements/PreviewImage';
import { useState } from 'react';
import { PreviewImageState } from 'types/image.interface';
import { dateFormat, pmojiTypeFormat } from 'utils/date';
import './style.scss';

export interface tableHead {
  name: string;
}

const TabelComponent = ({
  dataHead,
  databody,
  actionShow,
  actionDelete,
  actionEdit,
  width,
  height,
  margin,
  background
}: {
  dataHead: tableHead[];
  databody: any | [];
  actionShow?: (index: number) => void;
  actionDelete?: (index: number) => void;
  actionEdit?: (index: number) => void;
  width?: string;
  height?: string;
  margin?: string;
  background?: string;
}) => {
  const [preview, setPreview] = useState<PreviewImageState>({
    open: false,
    image: { src: null, alt: 'Pmoji' }
  });

  const handleClickOpen = (imagePreviews: any, index: number) => {
    if (actionShow) {
      actionShow(index);
    }
  };
  const handleDelete = (index: number) => {
    if (actionDelete) {
      actionDelete(index);
    }
  };
  const handleEdit = (index: number) => {
    if (actionEdit) {
      actionEdit(index);
    }
  };

  const handlePreview = (image: string) => {
    setPreview({
      image: { src: image, alt: 'Pmoji' },
      open: true
    });
  };

  return (
    <div>
      <TableContainer
        sx={{
          maxHeight: height ? height : 'auto',
          margin: margin ? margin : 'rem(30px 0)'
        }}
        className="table-container"
        component={Paper}
      >
        <Table
          stickyHeader
          sx={{
            minWidth: width ? width : 700
          }}
          aria-label="table"
        >
          <TableHead>
            <TableRow>
              {dataHead.map((item, index: number) => (
                <TableCell
                  align="center"
                  key={index}
                  className={background ? 'background' : ''}
                >
                  {item.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {databody.map((row: any | [], index: number) => (
              <TableRow
                className="row-table"
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => handleClickOpen(row.imagePreviews, index)}
              >
                <>
                  {Object.keys(row).map((key) => (
                    <TableCell align="center" key={key} className={key}>
                      {(() => {
                        switch (key) {
                          case 'numberAccount':
                            return row[key] + index + 1;
                          case 'created_at':
                            return dateFormat(row[key], 'YY.MM.DD');
                          case 'adminCreatedAt':
                            return dateFormat(row[key], 'YYYY.MM.DD');
                          case 'image':
                            return <Image src={row[key]} alt="Pmoji" />;
                          case 'imagePreview':
                            return (
                              <div
                                className="image-preview"
                                onClick={() => handlePreview(row[key])}
                              >
                                <Image src={row[key]?.[0]?.url} alt="" />
                              </div>
                            );

                          case 'pmojiType':
                            return pmojiTypeFormat(row[key]);
                          case 'status':
                            return row[key] === MEMBER_STATUS.SUSPENSION
                              ? '정지'
                              : '-';
                          case 'role':
                            return row[key] === ADMIN_ROLE.ADMIN
                              ? '권리자'
                              : '소유자';
                          case 'delete':
                            return (
                              <Box className="delete-button">
                                <ButtonCustom
                                  title={row[key]}
                                  cate="standard"
                                  onClick={() => handleDelete(index)}
                                />
                              </Box>
                            );
                          case 'deleteAdmin':
                            return (
                              row?.role === ADMIN_ROLE.ADMIN && (
                                <Box className="delete-button">
                                  <ButtonCustom
                                    title={row[key]}
                                    cate="standard"
                                    disabled={row.adminStatus === 'DELETE'}
                                    onClick={() => handleDelete(index)}
                                  />
                                </Box>
                              )
                            );
                          case 'edit':
                            return (
                              <Box className="edit-button">
                                <ButtonCustom
                                  title={row[key]}
                                  cate="standard"
                                  onClick={() => handleEdit(index)}
                                />
                              </Box>
                            );
                          case 'content':
                            return <div title={row[key]}>{row[key]}</div>;

                          case 'management':
                            return (
                              <Box className="delete-button">
                                <ButtonCustom
                                  title={row[key]}
                                  cate="standard"
                                  onClick={() => handleEdit(index)}
                                />
                              </Box>
                            );

                          default:
                            return <div>{row[key]}</div>;
                        }
                      })()}
                    </TableCell>
                  ))}
                </>
              </TableRow>
            ))}
            {(!databody || !databody.length) && (
              <TableRow>
                <TableCell sx={{ border: 0 }} colSpan={9}>
                  <div className="no-data">검색 결과가 없습니다.</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PreviewImage
        image={preview.image}
        open={preview.open}
        onClose={() =>
          setPreview({ open: false, image: { src: '', alt: 'Pmoji' } })
        }
      />
    </div>
  );
};

export default TabelComponent;
