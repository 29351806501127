import axios from 'axios';

interface GetKakaoAddressResponse {
  documents: KakaoDocuments[];
}

interface KakaoDocuments {
  address_name: string;
  x: string;
  y: string;
}

export const GetKakaoAddress = async (keyword: string): Promise<KakaoDocuments[]> => {
  try {
    const { data } = await axios.get<GetKakaoAddressResponse>(
      `https://dapi.kakao.com/v2/local/search/address?query=${keyword}`,
      {
        headers: {
          Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_API}`,
        },
      }
    );

    return data.documents;
  } catch {
    return [];
  }
}