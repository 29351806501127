import api, { CoreResponse } from '../api';

interface FetchActiveSugarsResponse extends CoreResponse {
  sugars: number;
}

export const FetchActiveSugars = async (): Promise<number> => {
  try {
    const { data } = await api.get<FetchActiveSugarsResponse>('/api/admin/v2/users/all/statistics/sugars');

    return data.sugars;
  } catch {
    return 0;
  }
}