import DaumPostcode, { Address } from 'react-daum-postcode';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

interface SearchAddressModalProps {
  open: boolean;
  onClose: () => void;
  onSearchComplete: (address: Address) => void;
}

export default function SearchAddressModal({
  open,
  onClose,
  onSearchComplete,
}: SearchAddressModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="search-address-modal"
      aria-describedby="search-address-modal-description"
    >
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box>
          <DaumPostcode
            onComplete={onSearchComplete}
          />
        </Box>
      </Box>
    </Modal>
  );
}
