import api from './api';
import { Prendies, PrendyApplicationStatus } from '../constants/prendy.constants';

export interface ApplicationPrendy {
  id: number;
  applicationPrendy: Prendies;
  status: PrendyApplicationStatus;
  createdAt: string;
  user: {
    id: number;
    alias: string;
    postCount: number;
    followersCount: number;
    createdAt: string;
  }
}

export const FetchAllApplicationPrendies = async (): Promise<ApplicationPrendy[]> => {
  try {
    const { data } = await api.get('/api/admin/v2/users/all/prendies');

    return data.applicationPrendies;
  } catch {
    return [];
  }
};

export const switchPrendyName = (prendy: string) => {
  switch (prendy) {
    case 'BEGINNER':
      return '비기너';
    case 'SILVER':
      return '실버';
    case 'GOLD':
      return '골드';
    case 'PRO':
      return '프로';
    default:
      return prendy;
  }
}

export enum ApplicationStatus {
  APPLICATION = 'APPLICATION',
  APPROVED = 'APPROV',
  REJECTED = 'REJECT',
}


export const ApproveOrRejectApplicationPrendy = async (applicationId: number, isApprove: boolean, rejectReason?: string): Promise<boolean> => {
  try {
    await api.put('/api/admin/v2/users/all/prendies', {
      applicationId,
      isApproved: isApprove,
      rejectReason,
    });

    return true;
  } catch {
    return false;
  }
}

export const RollbackApplicationPrendy = async (applicationId: number): Promise<boolean> => {
  try {
    await api.put(`/api/admin/v2/users/all/prendies/${applicationId}/rollback`);

    return true;
  } catch {
    return false;
  }
}
