import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import { Grid, IconButton } from '@mui/material';
import Input from 'elements/input';
import EyeIcon from 'assets/icons/eye';
import EyeCrossIcon from 'assets/icons/eye-cross';
import ButtonCustom from 'elements/Button';
import { useAppDispatch } from 'app/hooks';
import { signIn } from './userThunk';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { User } from 'types/user.interface';
import DialogError from 'dialog/DialogError';

const SignIn = () => {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [open, setOpen] = useState<{ open: boolean; message: string }>({
    open: false,
    message: 'Something went wrong!'
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('아이디를 입력해주세요'),
    password: Yup.string()
      .required('비밀번호 입력')
      .matches(
        /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.{6,})/,
        '영문,숫자 조합으로 6자리 이상 입력해주세요'
      )
  });

  const defaultValues = {
    email: '',
    password: ''
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues
  };
  const { handleSubmit, formState, control, trigger } = useForm<User>({
    mode: 'onChange',
    ...formOptions
  });
  const { errors } = formState;
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<User> = async (data) => {
    try {
      await dispatch(signIn(data)).unwrap();
      navigate('/dashboards');
    } catch (err: any) {
      setOpen({
        open: true,
        message: err?.meta?.messages[0]?.message ?? 'Something went wrong!'
      });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((s) => !s);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const EndComponent = () => {
    return (
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? <EyeIcon /> : <EyeCrossIcon />}
      </IconButton>
    );
  };

  const { password, email } = errors;

  return (
    <>
      <Box className="sign_in">
        {/* <Typography className="title_signin">관리자 로그인</Typography> */}
        <Box
          component="img"
          className="logo"
          sx={{
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 }
          }}
          alt="Prezem logo"
          src={require('../../assets/images/PREZEM_LOGO_LOGIN.png')}
        />
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Input
                type="text"
                name="email"
                trigger={trigger}
                triggerName="email"
                control={control}
                error={!!email}
                helperText={email && email.message}
                start={() => <PersonOutlineOutlinedIcon />}
                placeholder="아이디를 입력해주세요"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                type={showPassword ? 'text' : 'password'}
                name="password"
                trigger={trigger}
                triggerName="password"
                control={control}
                error={!!password}
                helperText={password && password.message}
                start={() => <LockOutlinedIcon />}
                end={EndComponent}
                placeholder="비밀번호 입력"
              />
            </Grid>
          </Grid>
          <Box className="signin_button">
            <ButtonCustom
              title="로그인"
              cate="standard"
              disabled={!!email || !!password}
            />
          </Box>
        </form>
      </Box>
      <DialogError
        message={open.message}
        open={open.open}
        handleClose={() => setOpen({ open: false, message: '' })}
      />
    </>
  );
};

export default SignIn;
