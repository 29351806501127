import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import ButtonCustom from 'elements/Button';
import TabelComponent from 'elements/TableCell';
import { SetStateAction, useEffect, useState } from 'react';
import MemberService from 'services/member.service';
import { CheckBoxDefault } from 'types/checkbox.interface';
import { DialogState } from 'types/dialog.interface';
import { categories, memberInfo, membersHead } from 'types/entities/member';
import { Member, MemberPagination } from 'types/member.interface';
import MemberDetailDialog from './components/MemberDetailDialog';
import './styles.scss';

const MemberInformation = () => {
  const [category, setCategory] = useState<string>('nickname');
  const [keySearch, setKeySearch] = useState<string>('');
  const [check, setCheck] = useState<CheckBoxDefault[]>([
    {
      value: true,
      name: 'maketing',
      label: '마케팅 수신동의 '
    }
  ]);
  const [data, setData] = useState<MemberPagination>(memberInfo);
  const [memberLists, setMemberLists] = useState([]);
  const [openDetailDialog, setOpenDetailDialog] = useState<DialogState<Member>>(
    { open: false, data: null }
  );

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const curVal: CheckBoxDefault[] = [...check];
    const newVal: CheckBoxDefault[] = curVal.map((val: CheckBoxDefault) => {
      if (val.name === event.target.name) {
        val.value = event.target.checked;
      }
      return val;
    });

    setCheck(newVal);
  };
  const handleCategoryChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setCategory(event.target.value);
  };
  const handleInputChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setKeySearch(event.target.value);
  };

  const getMemberList = async () => {
    const response = await MemberService.getMemberList({
      page: data?.current_page || 1,
      type: category,
      keyword: keySearch || '',
      isMarketing: check[0].value || ''
    });
    const { total_pages, current_page } = response?.data?.meta?.pagination;
    if (total_pages && current_page > total_pages) {
      setData((prevState: MemberPagination) => {
        return { ...prevState, current_page: 1 };
      });
    } else {
      setData((prevState: MemberPagination) => {
        return {
          ...prevState,
          total_pages: response?.data?.meta?.pagination?.total_pages,
          total: response?.data?.meta?.pagination?.total,
          data: response?.data?.data
        };
      });
      const bodyTables = response?.data?.data?.user.map((item: Member) => {
        return {
          id: item.id,
          uniqueId: item.uniqueId,
          image: item.characterSkin.url + `?${Date.now()}`,
          alias: item.alias,
          nickName: item.nickname,
          phone: item.phone ?? '-',
          marketing: item.isPushMarketingNotiConfig ? '동의' : '미동의',
          reportsNum: item.reportsNum,
          countShare: item.countShare + item.countOther + item.countRegion,
          created_at: item.created_at,
          status: item.status
        };
      }) as [];
      setMemberLists(bodyTables);
    }
  };

  const handlePaginationChange = (_: {}, p: number) => {
    setData((prevState: MemberPagination) => {
      return { ...prevState, current_page: p };
    });
  };

  const showDetail = async (index: number) => {
    const id = data?.data?.user[index]?.id;
    const res = await MemberService.getMemberByID(id);
    setOpenDetailDialog({
      open: true,
      data: res?.data?.data ?? null
    });
  };

  const handleSearch = () => {
    if (data.current_page !== 1) {
      setData((prevState: MemberPagination) => {
        return { ...prevState, current_page: 1 };
      });
    } else {
      getMemberList();
    }
  };

  useEffect(() => {
    getMemberList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.current_page, category, check]);

  return (
    <div className="main-content member">
      <h4 className="number-reported">
        신고 받은 회원 수 : {data?.data?.totalReportedMembers}
      </h4>
      <div className="member-content">
        <Box className="checkbox-group">
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              {check && check.length
                ? check.map((item: CheckBoxDefault, index: number) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={item.value}
                          onChange={(e) => handleCheck(e)}
                          name={item.name}
                          sx={{
                            color: '#FF6151',
                            '&.Mui-checked': {
                              color: '#FF6151'
                            }
                          }}
                        />
                      }
                      label={item.label}
                    />
                  ))
                : null}
            </FormGroup>
          </FormControl>
        </Box>
        <div className="section-search">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <div className="search-contain">
                <FormControl sx={{ m: 1 }} className="selection">
                  <Select
                    value={category}
                    onChange={handleCategoryChange}
                    displayEmpty
                  >
                    {categories && categories.length
                      ? categories.map((item) => (
                          <MenuItem
                            key={item.value}
                            className="menu-item"
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        ))
                      : ''}
                  </Select>
                </FormControl>
                <TextField
                  className="input-search"
                  value={keySearch}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter' || e.code === 'NumpadEnter')
                      handleSearch();
                  }}
                />
                <Box className="search-button">
                  <ButtonCustom
                    title="검색"
                    cate="standard"
                    onClick={handleSearch}
                  />
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <span className="number-searched">
                총 검색된 회원 수 : {data?.total}
              </span>
            </Grid>
          </Grid>
        </div>
        <div className="section-member-list">
          <TabelComponent
            dataHead={membersHead}
            databody={memberLists}
            actionShow={showDetail}
          />
          <MemberDetailDialog
            open={openDetailDialog.open}
            data={openDetailDialog.data}
            handleClose={() => setOpenDetailDialog({ open: false, data: null })}
            handleRefresh={getMemberList}
          />
        </div>
        <Pagination
          className="section-pagination"
          count={data?.total_pages || 1}
          page={data?.current_page || 1}
          onChange={handlePaginationChange}
          variant="outlined"
        />
      </div>
    </div>
  );
};

export default MemberInformation;
