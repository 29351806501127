import Modal from '@mui/material/Modal';
import React, { useCallback, useEffect } from 'react';
import { Box, Button, Grid } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface ImageOrderChangeModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  imageUrls: string[];
  onSave: (urls: string[]) => void;
}

const useImageOrderChangeModal = (
  imageUrls: string[],
) => {
  const [images, setImages] = React.useState<string[]>([]);

  useEffect(() => {
    setImages(imageUrls);
  }, [imageUrls]);

  const onClickUp = useCallback((index: number) => {
    if (index === 0) {
      return;
    }

    const newImages = [...images];
    const temp = newImages[index];
    newImages[index] = newImages[index - 1];
    newImages[index - 1] = temp;
    setImages(newImages);
  }, [images]);

  const onClickDown = useCallback((index: number) => {
    if (index === images.length - 1) {
      return;
    }

    const newImages = [...images];
    const temp = newImages[index];
    newImages[index] = newImages[index + 1];
    newImages[index + 1] = temp;
    setImages(newImages);
  }, [images]);

  return {
    models: {
      images,
    },
    operations: {
      onClickUp,
      onClickDown,
    },
  };
}

export default function ImageOrderChangeModal({
  open,
  onClose,
  title,
  imageUrls,
  onSave,
}: ImageOrderChangeModalProps) {
  const { models, operations } = useImageOrderChangeModal(imageUrls);

  return (
    <Modal open={open}
           onClose={onClose}
           aria-labelledby="image-order-change-modal"
           aria-describedby="image-order-change-modal-description">
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "60%",
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container mb={5}>
          <Grid item
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
                xs={12}>
            <h2>{title}</h2> &nbsp;&nbsp;
            <Button variant="contained"
                    color="primary"
                    onClick={() => onSave(models.images)}>
              저장
            </Button>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item
                xs={12}>
            <div style={{
              maxHeight: '600px',
              overflowY: 'auto',
            }}>
              <TableContainer component={Paper}>
                <Table sx={{
                  width: "100%",
                }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">이미지</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {models.images.map((url, index) => (
                      <TableRow key={url}>
                        <TableCell align="center">
                          <img
                            key={`image-${url}`}
                            src={url}
                            alt='이미지'
                            style={{
                              width: '200px',
                              height: '200px'
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Grid container>
                            {index !== 0 && (
                              <Grid item
                                    xs={12}>
                                <Button
                                  fullWidth
                                  variant='outlined'
                                  color='error'
                                  onClick={() => operations.onClickUp(index)}>
                                  위로 <ArrowUpwardIcon />
                                </Button>
                              </Grid>
                            )}
                            {index !== imageUrls.length - 1 && (
                              <Grid item
                                    xs={12}>
                                <Button
                                  fullWidth
                                  variant='outlined'
                                  color='error'
                                  onClick={() => operations.onClickDown(index)}>
                                  아래로 <ArrowDownwardIcon />
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>

      </Box>
    </Modal>
  );
}