import api, { CoreResponse } from '../api';

interface FetchAccumulatedSugarsResponse extends CoreResponse {
  accumulatedSugar: number;
}

export const FetchAccumulatedSugars = async (): Promise<number> => {
  try {
    const { data } = await api.get<FetchAccumulatedSugarsResponse>('/api/admin/v2/users/all/statistics/accumulated-sugars');

    return data.accumulatedSugar;
  } catch {
    return 0;
  }
}