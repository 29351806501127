import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { CloudUpload } from '@material-ui/icons';
import { VisuallyHiddenInput } from '../../../elements/v2/VisuallyHiddenInput/VisuallyHiddeninput';
import AdminDatePicker from '../../../elements/v2/AdminDatePicker/AdminDatePicker';
import Textarea from '../../../elements/v2/Textarea/Textarea';
import React, { useCallback, useEffect, useState } from 'react';
import { Address } from 'react-daum-postcode';
import SearchAddressModal from '../../../modals/SearchAddressModal';
import Typography from '@mui/material/Typography';
import { GetPresignedUrl, PresignedUrl, UploadFileUsingPresignedUrl } from '../../../services/uploads.service';
import Swal from 'sweetalert2'
import dayjs, { Dayjs } from 'dayjs';
import { GetKakaoAddress } from '../../../services/kakao-search-address.service';
import { CreatePopupStore, FetchPopupStoreDetail, UpdatePopupStore } from '../../../services/popup-stores.service';
import { useNavigate, useParams } from 'react-router-dom';
import Thumbnails from './components/Thumbnails';
import ImageOrderChangeModal from '../../../modals/ImageOrderChangerModal';
import useTemporaryPopupStores from './zustand/temporaryPopupStores';

interface Opening {
  opening: string;
  closing: string;
}

type OpeningDay = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
const openingDays: OpeningDay[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const convetKoreanDay = (day: OpeningDay) => {
  switch (day) {
    case 'monday':
      return '월';
    case 'tuesday':
      return '화';
    case 'wednesday':
      return '수';
    case 'thursday':
      return '목';
    case 'friday':
      return '금';
    case 'saturday':
      return '토';
    case 'sunday':
      return '일';
    default:
      return '';
  }
}

type Openings = {
  [day in OpeningDay]: Opening;
};

interface OpeningMap {
  [openingToClosing: string]: {
    opening: string;
    closing: string;
    days: OpeningDay[];
  };
}

const useCommandPopupStores = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { temporaryPopupStore, setTemporaryPopupStore } = useTemporaryPopupStores();

  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [descriptionTitle, setDescriptionTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [officialLink, setOfficialLink] = useState<string>('');
  const [isPreReservation, setIsPreReservation] = useState<boolean>(false);
  const [isTopBeforeStarted, setIsTopBeforeStarted] = useState<boolean>(false);
  const [reservationLink, setReservationLink] = useState<string>('');
  const [instagramLink, setInstagramLink] = useState<string>('');
  const [isAssociated, setIsAssociated] = useState<boolean>(false);
  const [isFreeEntrance, setIsFreeEntrance] = useState<boolean>(false);
  const [isParkingAvailable, setIsParkingAvailable] = useState<boolean>(false);
  const [hasEvent, setHasEvent] = useState<boolean>(false);
  const [eventTitle, setEventTitle] = useState<string>('');
  const [events, setEvents] = useState<string>('');
  const [logoImage, setLogoImage] = useState<string>('');
  const [thumbnailImages, setThumbnailImages] = useState<string[]>(['']);

  const [openings, setOpenings] = useState<Openings>({
    monday: {
      opening: '',
      closing: '',
    },
    tuesday: {
      opening: '',
      closing: '',
    },
    wednesday: {
      opening: '',
      closing: '',
    },
    thursday: {
      opening: '',
      closing: '',
    },
    friday: {
      opening: '',
      closing: '',
    },
    saturday: {
      opening: '',
      closing: '',
    },
    sunday: {
      opening: '',
      closing: '',
    },
  });
  const [openingMap, setOpeningMap] = useState<OpeningMap>({});

  const [searchAddressModalOpen, setSearchAddressModalOpen] = useState<boolean>(false);
  const [imageOrderChangeModalOpen, setImageOrderChangeModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!params.id) {
      setName(temporaryPopupStore.name);
      setAddress(temporaryPopupStore.address);
      setLatitude(temporaryPopupStore.latitude);
      setLongitude(temporaryPopupStore.longitude);
      setDescriptionTitle(temporaryPopupStore.descriptionTitle);
      setDescription(temporaryPopupStore.description);
      setStartDate(temporaryPopupStore.startDate);
      setEndDate(temporaryPopupStore.endDate);
      setOfficialLink(temporaryPopupStore.officialLink);
      setIsPreReservation(temporaryPopupStore.isPreReservation);
      setIsTopBeforeStarted(temporaryPopupStore.isTopBeforeStarted);
      setReservationLink(temporaryPopupStore.reservationLink);
      setInstagramLink(temporaryPopupStore.instagramLink);
      setIsAssociated(temporaryPopupStore.isAssociated);
      setIsFreeEntrance(temporaryPopupStore.isFreeEntrance);
      setIsParkingAvailable(temporaryPopupStore.isParkingAvailable);
      setHasEvent(temporaryPopupStore.hasEvent);
      setEventTitle(temporaryPopupStore.eventTitle);
      setEvents(temporaryPopupStore.events);
      setLogoImage(temporaryPopupStore.logoImage);
      setThumbnailImages(temporaryPopupStore.thumbnailImages);
      return;
    }

    async function fetchPopupStore() {
      const popupStore = await FetchPopupStoreDetail(Number(params.id));
      const { name, address, latitude, longitude, description, startDate, endDate, officialLink, reservationLink, instagramLink, isAssociated,
        isFreeEntrance,
        isParkingAvailable,
        eventsTitle,
        events,
        logoImage,
        thumbnailImage1,
        thumbnailImage2,
        thumbnailImage3,
        thumbnailImage4,
        thumbnailImage5,
        thumbnailImage6,
        thumbnailImage7,
        thumbnailImage8,
        thumbnailImage9,
        thumbnailImage10, monday, tuesday, wednesday, thursday, friday, saturday, sunday } = popupStore;

      setName(name);
      setAddress(address);
      setLatitude(latitude);
      setLongitude(longitude);
      setDescriptionTitle(popupStore.descriptionTitle);
      setDescription(description);
      setStartDate(dayjs(startDate));
      setEndDate(dayjs(endDate));
      setOfficialLink(officialLink);
      setIsPreReservation(popupStore.isPreReservation);
      setIsTopBeforeStarted(popupStore.isTopBeforeStarted);
      setReservationLink(reservationLink);
      setInstagramLink(instagramLink);
      setIsAssociated(isAssociated);
      setIsFreeEntrance(isFreeEntrance);
      setIsParkingAvailable(isParkingAvailable);
      setHasEvent(eventsTitle || events);
      setEventTitle(eventsTitle);
      setEvents(events);
      setLogoImage(logoImage);
      setThumbnailImages([thumbnailImage1, thumbnailImage2, thumbnailImage3, thumbnailImage4, thumbnailImage5, thumbnailImage6, thumbnailImage7, thumbnailImage8, thumbnailImage9, thumbnailImage10].filter((thumbnailImage: string) => thumbnailImage));

      setOpenings({
        monday: {
          opening: monday ? monday.split('~')[0] : '',
          closing: monday ? monday.split('~')[1] : '',
        },
        tuesday: {
          opening: tuesday ? tuesday.split('~')[0] : '',
          closing: tuesday ? tuesday.split('~')[1] : '',
        },
        wednesday: {
          opening: wednesday ? wednesday.split('~')[0] : '',
          closing: wednesday ? wednesday.split('~')[1] : '',
        },
        thursday: {
          opening: thursday ? thursday.split('~')[0] : '',
          closing: thursday ? thursday.split('~')[1] : '',
        },
        friday: {
          opening: friday ? friday.split('~')[0] : '',
          closing: friday ? friday.split('~')[1] : '',
        },
        saturday: {
          opening: saturday ? saturday.split('~')[0] : '',
          closing: saturday ? saturday.split('~')[1] : '',
        },
        sunday: {
          opening: sunday ? sunday.split('~')[0] : '',
          closing: sunday ? sunday.split('~')[1] : '',
        },
      });
    }

    fetchPopupStore();
  }, [params]);

  useEffect(() => {
    Object.keys(openings).forEach((key: string, index: number) => {
      const day = key as OpeningDay;
      const opening = openings[day];

      if (!opening.opening || !opening.closing) {
        return;
      }

      const openingMapKey = opening.opening + '~' + opening.closing;

      setOpeningMap((prev: OpeningMap) => {
        const newOpeningMap = { ...prev };

        if (!newOpeningMap[openingMapKey]) {
          newOpeningMap[openingMapKey] = {
            opening: opening.opening,
            closing: opening.closing,
            days: [],
          };
        }

        newOpeningMap[openingMapKey].days.push(day);

        return newOpeningMap;
      });
    });
  }, [openings]);

  const onClickNewOpening = useCallback(() => {
    const newOpeningMapKey = `new-${Date.now()}`;

    setOpeningMap((prev: OpeningMap) => {
      const newOpeningMap = { ...prev };
      newOpeningMap[newOpeningMapKey] = {
        opening: '',
        closing: '',
        days: openingDays,
      };

      return newOpeningMap;
    });
  }, []);

  const handleDescriptionChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  }, []);
  const handleSearchAddressButtonClicked = () => setSearchAddressModalOpen(true);
  const handleSearchAddressModalClosed = () => setSearchAddressModalOpen(false);
  const handleSearchAddressCompleted = useCallback(async (address: Address) => {
    const documents = await GetKakaoAddress(address.address);
    const document = documents[0];

    setSearchAddressModalOpen(false);
    setAddress(address.address);
    setLatitude(document.y);
    setLongitude(document.x);
  }, []);

  const handleImageOrderChangeModalOpen = () => setImageOrderChangeModalOpen(true);
  const handleImageOrderChangeModalClosed = () => setImageOrderChangeModalOpen(false);
  const handleImageOrderChangeModalSave = (urls: string[]) => {
    setThumbnailImages(urls);
    setImageOrderChangeModalOpen(false);

    Swal.fire('이미지 순서 변경 완료', '아래 저장하기 버튼으로 변경사항을 적용해야 합니다.', 'success');
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<string[]> => {
    if (!e.target.files) {
      return [];
    }

    const urls: string[] = [];
    Swal.showLoading();
    for await (const file of e.target.files) {
      const presignedUrl: PresignedUrl = await GetPresignedUrl();

      const isSuccess: boolean = await UploadFileUsingPresignedUrl({
        url: `https://s3.ap-northeast-2.amazonaws.com/${presignedUrl.fields.bucket}`,
        'X-Amz-Algorithm': presignedUrl.fields['X-Amz-Algorithm'],
        'X-Amz-Credential': presignedUrl.fields['X-Amz-Credential'],
        'X-Amz-Signature': presignedUrl.fields['X-Amz-Signature'],
        'X-Amz-Date': presignedUrl.fields['X-Amz-Date'],
        bucket: presignedUrl.fields.bucket,
        key: presignedUrl.fields.key,
        Policy: presignedUrl.fields.Policy,
        'Content-Type': file.type,
        file,
      });

      if (!isSuccess) {
        Swal.fire('이미지 업로드 실패', '이미지 업로드에 실패했습니다. 다시 시도해주세요.', 'error');
        return [];
      }

      urls.push(presignedUrl.uploadedUrl);
    }
    Swal.close();

    return urls;
  }

  const onClickHistoryBack = () => {
    navigate('/popup-stores');
  }

  const onDeletedThumbnail = useCallback((imageUrl: string) => {
    const newThumbnailImages = thumbnailImages.filter((image: string) => image !== imageUrl);
    setThumbnailImages(newThumbnailImages);
  }, [thumbnailImages]);


  const onBack = useCallback(() => {
    setTemporaryPopupStore({
      name,
      address,
      latitude,
      longitude,
      descriptionTitle,
      description,
      startDate,
      endDate,
      officialLink,
      isPreReservation,
      isTopBeforeStarted,
      reservationLink,
      instagramLink,
      isAssociated,
      isFreeEntrance,
      isParkingAvailable,
      hasEvent,
      eventTitle,
      events,
      logoImage,
      thumbnailImages,
    });
  }, [address, description, descriptionTitle, endDate, eventTitle, events, hasEvent, instagramLink, isAssociated, isFreeEntrance, isParkingAvailable, isPreReservation, isTopBeforeStarted, latitude, logoImage, longitude, name, officialLink, reservationLink, setTemporaryPopupStore, startDate, thumbnailImages]);

  useEffect(() => {
    window.addEventListener("popstate", onBack);
  }, [onBack]);

  return {
    models: {
      id: params.id,
      navigate,
      searchAddressModalOpen,
      logoImage,
      thumbnailImages,
      name,
      address,
      latitude,
      longitude,
      descriptionTitle,
      description,
      startDate,
      endDate,
      officialLink,
      isPreReservation,
      isTopBeforeStarted,
      reservationLink,
      instagramLink,
      isAssociated,
      isFreeEntrance,
      isParkingAvailable,
      hasEvent,
      eventTitle,
      events,
      openingMap,
      imageOrderChangeModalOpen,
    },
    operations: {
      handleImageUpload,
      handleSearchAddressButtonClicked,
      setSearchAddressModalOpen,
      handleSearchAddressModalClosed,
      handleSearchAddressCompleted,
      setLogoImage,
      setThumbnailImages,
      setName,
      setAddress,
      setDescriptionTitle,
      setDescription,
      handleDescriptionChange,
      setStartDate,
      setEndDate,
      setOfficialLink,
      setIsPreReservation,
      setIsTopBeforeStarted,
      setReservationLink,
      setInstagramLink,
      setIsAssociated,
      setIsFreeEntrance,
      setIsParkingAvailable,
      setHasEvent,
      setEventTitle,
      setEvents,
      onClickNewOpening,
      setOpeningMap,
      onClickHistoryBack,
      onDeletedThumbnail,
      handleImageOrderChangeModalOpen,
      handleImageOrderChangeModalClosed,
      handleImageOrderChangeModalSave,
      setTemporaryPopupStore,
    },
  };
}

export function CommandPopupStores() {
  const { models, operations } = useCommandPopupStores();

  return (
    <div className="main-content">
      <Grid container>
        <Grid item xs={12}>
          {models.id ? (
            <Box
              display={'flex'}
            >
              <h1>팝업스토어 조회/수정하기</h1>
              <Box ml={1} mt={1}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={operations.onClickHistoryBack}
                >
                목록으로 돌아가기
                </Button>
              </Box>
            </Box>
          ) : (
            <h1>팝업스토어 추가하기</h1>
          )}
        </Grid>
      </Grid>

      <Grid container mt={3}>
        <Grid container mb={1} spacing={1}>
          <Grid item xs={4}>
            <TextField
              required
              fullWidth
              id="name"
              label="팝업스토어명"
              value={models.name || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => operations.setName(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} mb={1}>
          <Grid item xs={10}>
            <TextField
              required
              fullWidth
              label="주소"
              value={models.address || ""}
              disabled
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => operations.setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            {models.id ? (
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
              >
                &nbsp;
              </Box>
              ) : (
              <Button
                variant='contained'
                color={'secondary'}
                fullWidth
                onClick={operations.handleSearchAddressButtonClicked}
                sx={{
                  height: '100%'
                }}
              >
                검색
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item
                xs={12}
                mb={1} >
            <TextField
              fullWidth
              id="descriptionTitle"
              label="설명 제목"
              value={models.descriptionTitle || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => operations.setDescriptionTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Textarea
                required
                minRows={3}
                label="팝업스토어 설명"
                value={models.description || ""}
                onChange={operations.handleDescriptionChange}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <AdminDatePicker
              required
              label="시작일"
              value={models.startDate || null}
              onChange={(dayjs: Dayjs) => operations.setStartDate(dayjs)}
            />
          </Grid>
          <Grid item xs={4}>
            <AdminDatePicker
              required
              label="종료일"
              value={models.endDate || null}
              onChange={(dayjs: Dayjs) => operations.setEndDate(dayjs)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} mt={1}>
          <Grid item xs={4}>
            <TextField
              required
              fullWidth
              label='공식주소 링크'
              value={models.officialLink || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => operations.setOfficialLink(e.target.value)}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              label='예약주소 링크'
              value={models.reservationLink || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => operations.setReservationLink(e.target.value)}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              label='인스타그램 링크'
              value={models.instagramLink || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => operations.setInstagramLink(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} mt={2}>
          <Grid item>
            <Box
              display="flex"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                alignItems="center"
                >
                <h3>영업 시간</h3>
              </Box>
              <Box ml={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={operations.onClickNewOpening}
                >+ 추가하기</Button>
              </Box>
            </Box>
          </Grid>

            {Object.keys(models.openingMap).map((key: string, index: number ) => {
              const openingMap = models.openingMap[key];
              let isNew = key.includes("new");

              const { opening, closing } = openingMap;
              const days = models.openingMap[key].days;

              return (
                <Grid item xs={12} key={`grid-${key}-${index}`}>
                  <Box
                    key={`box-${key}-${index}`}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={1}
                  >
                    <Grid item xs={2}>
                      {isNew ? (
                        <Box>
                          <FormControl>
                            <InputLabel id="opening">오픈 시간</InputLabel>
                            <Select
                              labelId="opening"
                              label="오픈 시간"
                              value={opening || "선택"}
                              onChange={(e: SelectChangeEvent) => {
                                const newOpeningMap = models.openingMap[key];
                                newOpeningMap.opening = e.target.value as string;
                                operations.setOpeningMap({
                                  ...models.openingMap,
                                  [key]: newOpeningMap,
                                });
                              }}
                            >
                              <MenuItem value="선택">선택</MenuItem>
                              <MenuItem value="07:00">07:00</MenuItem>
                              <MenuItem value="07:30">07:30</MenuItem>
                              <MenuItem value="08:00">08:00</MenuItem>
                              <MenuItem value="08:30">08:30</MenuItem>
                              <MenuItem value="09:00">09:00</MenuItem>
                              <MenuItem value="09:30">09:30</MenuItem>
                              <MenuItem value="10:00">10:00</MenuItem>
                              <MenuItem value="10:30">10:30</MenuItem>
                              <MenuItem value="11:00">11:00</MenuItem>
                              <MenuItem value="11:30">11:30</MenuItem>
                              <MenuItem value="12:00">12:00</MenuItem>
                              <MenuItem value="12:30">12:30</MenuItem>
                              <MenuItem value="13:00">13:00</MenuItem>
                              <MenuItem value="13:30">13:30</MenuItem>
                              <MenuItem value="14:00">14:00</MenuItem>
                              <MenuItem value="14:30">14:30</MenuItem>
                              <MenuItem value="15:00">15:00</MenuItem>
                              <MenuItem value="15:30">15:30</MenuItem>
                              <MenuItem value="16:00">16:00</MenuItem>
                              <MenuItem value="16:30">16:30</MenuItem>
                              <MenuItem value="17:00">17:00</MenuItem>
                              <MenuItem value="17:30">17:30</MenuItem>
                              <MenuItem value="18:00">18:00</MenuItem>
                              <MenuItem value="18:30">18:30</MenuItem>
                              <MenuItem value="19:00">19:00</MenuItem>
                              <MenuItem value="19:30">19:30</MenuItem>
                              <MenuItem value="20:00">20:00</MenuItem>
                              <MenuItem value="20:30">20:30</MenuItem>
                              <MenuItem value="21:00">21:00</MenuItem>
                              <MenuItem value="21:30">21:30</MenuItem>
                              <MenuItem value="22:00">22:00</MenuItem>
                              <MenuItem value="22:30">22:30</MenuItem>
                              <MenuItem value="23:00">23:00</MenuItem>
                              <MenuItem value="23:30">23:30</MenuItem>
                            </Select>
                          </FormControl>&nbsp;
                          <FormControl>
                            <InputLabel id="opening">마감 시간</InputLabel>
                            <Select
                              labelId="opening"
                              label="마감 시간"
                              value={closing || "선택"}
                              onChange={(e: SelectChangeEvent) => {
                                const newOpeningMap = models.openingMap[key];
                                newOpeningMap.closing = e.target.value as string;
                                operations.setOpeningMap({
                                  ...models.openingMap,
                                  [key]: newOpeningMap,
                                });
                              }}
                            >
                              <MenuItem value="선택">선택</MenuItem>
                              <MenuItem value="07:00">07:00</MenuItem>
                              <MenuItem value="07:30">07:30</MenuItem>
                              <MenuItem value="08:00">08:00</MenuItem>
                              <MenuItem value="08:30">08:30</MenuItem>
                              <MenuItem value="09:00">09:00</MenuItem>
                              <MenuItem value="09:30">09:30</MenuItem>
                              <MenuItem value="10:00">10:00</MenuItem>
                              <MenuItem value="10:30">10:30</MenuItem>
                              <MenuItem value="11:00">11:00</MenuItem>
                              <MenuItem value="11:30">11:30</MenuItem>
                              <MenuItem value="12:00">12:00</MenuItem>
                              <MenuItem value="12:30">12:30</MenuItem>
                              <MenuItem value="13:00">13:00</MenuItem>
                              <MenuItem value="13:30">13:30</MenuItem>
                              <MenuItem value="14:00">14:00</MenuItem>
                              <MenuItem value="14:30">14:30</MenuItem>
                              <MenuItem value="15:00">15:00</MenuItem>
                              <MenuItem value="15:30">15:30</MenuItem>
                              <MenuItem value="16:00">16:00</MenuItem>
                              <MenuItem value="16:30">16:30</MenuItem>
                              <MenuItem value="17:00">17:00</MenuItem>
                              <MenuItem value="17:30">17:30</MenuItem>
                              <MenuItem value="18:00">18:00</MenuItem>
                              <MenuItem value="18:30">18:30</MenuItem>
                              <MenuItem value="19:00">19:00</MenuItem>
                              <MenuItem value="19:30">19:30</MenuItem>
                              <MenuItem value="20:00">20:00</MenuItem>
                              <MenuItem value="20:30">20:30</MenuItem>
                              <MenuItem value="21:00">21:00</MenuItem>
                              <MenuItem value="21:30">21:30</MenuItem>
                              <MenuItem value="22:00">22:00</MenuItem>
                              <MenuItem value="22:30">22:30</MenuItem>
                              <MenuItem value="23:00">23:00</MenuItem>
                              <MenuItem value="23:30">23:30</MenuItem>
                              <MenuItem value="24:00">24:00</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      ) : (
                        <Typography>{opening} ~ {closing}</Typography>
                      )}
                    </Grid>
                    {openingDays.map((day: OpeningDay) => {
                      return (
                        <Grid item xs={1} key={`grid-item-${day}`}>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox />}
                              label={convetKoreanDay(day)}
                              value={day}
                              checked={days.includes(day)}
                              onChange={(e, checked) => {
                                const newOpeningMap = models.openingMap[key];

                                if (checked) {
                                  newOpeningMap.days.push(day);
                                } else {
                                  newOpeningMap.days = newOpeningMap.days.filter((d: OpeningDay) => d !== day);
                                }

                                operations.setOpeningMap({
                                  ...models.openingMap,
                                  [key]: newOpeningMap,
                                });
                              }}
                            />
                          </FormGroup>
                        </Grid>
                      );
                    })}
                  </Box>
                </Grid>
              );
            })}
        </Grid>

        <Grid container spacing={1} mt={1}>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="제휴 팝업스토어 여부"
                checked={models.isAssociated as boolean}
                onChange={(e, checked) => operations.setIsAssociated(checked)}
              />
            </FormGroup>
          </Grid>

          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="무료입장 가능"
                checked={models.isFreeEntrance as boolean}
                onChange={(e, checked) => operations.setIsFreeEntrance(checked)}
              />
            </FormGroup>
          </Grid>

          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="무료주차 가능"
                checked={models.isParkingAvailable as boolean}
                onChange={(e, checked) => operations.setIsParkingAvailable(checked)}
              />
            </FormGroup>
          </Grid>

          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="사전예약 가능"
                checked={models.isPreReservation as boolean}
                onChange={(e, checked) => operations.setIsPreReservation(checked)}
              />
            </FormGroup>
          </Grid>

          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="오픈예정시 최상단 위치"
                checked={models.isTopBeforeStarted as boolean}
                onChange={(e, checked) => operations.setIsTopBeforeStarted(checked)}
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid container spacing={1} mt={1}>
          <Grid item xs={12}>
            <h3>이미지 업로드</h3>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
                fullWidth
                color={'secondary'}
                sx={{
                  height: '100%'
                }}
              >
                팝업스토어 로고 이미지
                <VisuallyHiddenInput
                  type='file'
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    const uploadUrls: string[] = await operations.handleImageUpload(e);

                    if (!uploadUrls || !uploadUrls[0]) {
                      return;
                    }

                    operations.setLogoImage(uploadUrls[0]);
                  }}
                />
              </Button>
            </Grid>
            <Grid item xs={models.thumbnailImages && models.thumbnailImages.length > 0 ? 4 : 6}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
                fullWidth
                color={'secondary'}
                sx={{
                  height: '100%'
                }}
              >
                썸네일 이미지
                <VisuallyHiddenInput
                  type='file'
                  multiple
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    const uploadUrls: string[] = await operations.handleImageUpload(e);

                    if (!uploadUrls || uploadUrls.length === 0) {
                      return;
                    }

                    operations.setThumbnailImages([...models.thumbnailImages, ...uploadUrls]);
                  }}
                />
              </Button>
            </Grid>
            {models.thumbnailImages && models.thumbnailImages.length > 0 && (
              <Grid item xs={2}>
                <Button
                  variant='outlined'
                  color='secondary'
                  fullWidth
                  onClick={operations.handleImageOrderChangeModalOpen}
                >
                  이미지 순서 변경
                </Button>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={1} mt={1}>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Box>
                    {models.logoImage && (
                      <img
                        src={models.logoImage}
                        alt="팝업스토어 로고 이미지"
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                      />)}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                {[...models.thumbnailImages] && [...models.thumbnailImages].length > 0 &&
                  [...models.thumbnailImages].map((thumbnailImage: string, index: number) => {
                    if (!thumbnailImage) {
                      return null;
                    }

                    return (
                      <Thumbnails
                        id={index}
                        url={thumbnailImage}
                        onDelete={() => operations.onDeletedThumbnail(thumbnailImage)} />
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container mt={1}>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox />}
              label="이벤트 여부"
              checked={models.hasEvent}
              onChange={(e, checked) => operations.setHasEvent(checked)}
            />
          </Grid>

          {models.hasEvent && (
            <Grid item xs={12} mb={1}>
              <TextField
                required
                fullWidth
                id="event_title"
                label="이벤트 제목"
                value={models.eventTitle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => operations.setEventTitle(e.target.value)}
              />
            </Grid>
          )}

          {models.hasEvent && (
            <Grid item xs={12}>
              <Box>
                <Textarea
                  minRows={3}
                  label='이벤트 내용'
                  value={models.events || ''}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => operations.setEvents(e.target.value)}
                />
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          direction='row-reverse'
          mt={2}
        >
          <Grid item xs={2}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={async ()=> {
                const openingDays: {
                  [day in OpeningDay]: Opening;
                } = {
                  monday: {
                    opening: '',
                    closing: '',
                  },
                  tuesday: {
                    opening: '',
                    closing: '',
                  },
                  wednesday: {
                    opening: '',
                    closing: '',
                  },
                  thursday: {
                    opening: '',
                    closing: '',
                  },
                  friday: {
                    opening: '',
                    closing: '',
                  },
                  saturday: {
                    opening: '',
                    closing: '',
                  },
                  sunday: {
                    opening: '',
                    closing: '',
                  },
                };

                Object.keys(models.openingMap).forEach((key: string) => {
                  const openingMap = models.openingMap[key];
                  const { opening, closing } = openingMap;
                  const days = models.openingMap[key].days;

                  if (!opening || !closing || days.length === 0) {
                    Swal.fire('영업시간 누락', '영업시간을 모두 입력해주세요', 'error');
                    return;
                  }

                  days.forEach((day: OpeningDay) => {
                    openingDays[day] = {
                      opening,
                      closing,
                    };
                  });
                });

                const saveRequest = {
                  title: models.name,
                  descriptionTitle: models.descriptionTitle,
                  description: models.description,
                  address: models.address,
                  latitude: Number(models.latitude),
                  longitude: Number(models.longitude),
                  startDate: models.startDate?.format('YYYY-MM-DD') || '',
                  endDate: models.endDate?.format('YYYY-MM-DD') || '',
                  logoImage: models.logoImage,
                  thumbnailImages: models.thumbnailImages || [],
                  monday: openingDays.monday.opening === '' || openingDays.monday.closing === '' ? '' : `${openingDays.monday.opening}~${openingDays.monday.closing}`,
                  tuesday: openingDays.tuesday.opening === '' || openingDays.tuesday.closing === '' ? '' : `${openingDays.tuesday.opening}~${openingDays.tuesday.closing}`,
                  wednesday: openingDays.wednesday.opening === '' || openingDays.wednesday.closing === '' ? '' : `${openingDays.wednesday.opening}~${openingDays.wednesday.closing}`,
                  thursday: openingDays.thursday.opening === '' || openingDays.thursday.closing === '' ? '' : `${openingDays.thursday.opening}~${openingDays.thursday.closing}`,
                  friday: openingDays.friday.opening === '' || openingDays.friday.closing === '' ? '' : `${openingDays.friday.opening}~${openingDays.friday.closing}`,
                  saturday: openingDays.saturday.opening === '' || openingDays.saturday.closing === '' ? '' : `${openingDays.saturday.opening}~${openingDays.saturday.closing}`,
                  sunday: openingDays.sunday.opening === '' || openingDays.sunday.closing === '' ? '' : `${openingDays.sunday.opening}~${openingDays.sunday.closing}`,
                  isAssociated: models.isAssociated,
                  isParkingAvailable: models.isParkingAvailable,
                  isFreeEntrance: models.isFreeEntrance,
                  instagramLink: models.instagramLink,
                  officialLink: models.officialLink,
                  isPreReservation: models.isPreReservation,
                  isTopBeforeStarted: models.isTopBeforeStarted,
                  reservationLink: models.reservationLink,
                  eventsTitle: models.eventTitle,
                  events: models.events,
                };
                const isUpdate = !!models.id;
                const isSuccess = isUpdate ? await UpdatePopupStore(Number(models.id), saveRequest) : await CreatePopupStore(saveRequest);

                if (!isSuccess) {
                  Swal.fire(`팝업스토어 ${isUpdate ? '수정' : '추가'} 실패`, `팝업스토어 ${isUpdate ? '수정' : '추가'}에 실패했습니다. 다시 시도해주세요.`, 'error');
                  return;
                }

                Swal.fire(`팝업스토어 ${isUpdate ? '수정' : '추가'} 성공`, `팝업스토어 ${isUpdate ? '수정' : '추가'}에 성공했습니다.`, 'success');
                operations.setTemporaryPopupStore({
                  name: '',
                  address: '',
                  latitude: '',
                  longitude: '',
                  descriptionTitle: '',
                  description: '',
                  startDate: null,
                  endDate: null,
                  officialLink: '',
                  isPreReservation: false,
                  isTopBeforeStarted: false,
                  reservationLink: '',
                  instagramLink: '',
                  isAssociated: false,
                  isFreeEntrance: false,
                  isParkingAvailable: false,
                  hasEvent: false,
                  eventTitle: '',
                  events: '',
                  logoImage: '',
                  thumbnailImages: [],
                });
                models.navigate('/popup-stores');
              }}
              sx={{
                height: '100%'
              }}
            >저장하기</Button>
          </Grid>
        </Grid>
      </Grid>

      <section>
        <SearchAddressModal
          open={models.searchAddressModalOpen}
          onClose={operations.handleSearchAddressModalClosed}
          onSearchComplete={operations.handleSearchAddressCompleted}
        />

        <ImageOrderChangeModal open={models.imageOrderChangeModalOpen}
                               onClose={operations.handleImageOrderChangeModalClosed}
                               title={`${models.name} 썸네일 순서변경`}
                               imageUrls={models.thumbnailImages}
                               onSave={operations.handleImageOrderChangeModalSave} />
      </section>
    </div>
  );
}
