import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import React from 'react';
import { Button, FormControl, Grid, TextField } from '@mui/material';

interface PrendyApplicationApproveRejectModalProps {
  open: boolean;
  onClose: () => void;
  userId: string;
  applicationPrendy: string;
  applicationAt: string;
  postCount: number;
  followersCount: number;
  onClickApproveOrReject: (isApprove: boolean, rejectReason?: string) => void;
}

export default function PrendyApplicationApproveRejectModal({
  open,
  onClose,
  userId,
  applicationPrendy,
  applicationAt,
  postCount,
  followersCount,
  onClickApproveOrReject,
}: PrendyApplicationApproveRejectModalProps) {
  const [rejectReason, setRejectReason] = React.useState<string>("");

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="prendy-application-approve-reject-modal"
      aria-describedby="prendy-application-approve-reject-modal-description"
    >
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "60%",
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box>
          <Grid container mb={5}>
            <Grid item xs={12}>
              <h2>프렌디 신청 승인/거절 하기</h2>
            </Grid>
          </Grid>

          <Grid container mb={2} spacing={2}>
            <Grid item>
              <FormControl>
                <TextField
                  required
                  fullWidth
                  id="user_id"
                  label="유저 아이디"
                  value={userId ?? ""}
                  disabled
                />
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl>
                <TextField
                  required
                  fullWidth
                  id="application_prendy"
                  label="신청 프렌디"
                  value={applicationPrendy ?? ""}
                  disabled
                />
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl>
                <TextField
                  required
                  fullWidth
                  id="application_at"
                  label="신청 시간"
                  value={applicationAt ?? ""}
                  disabled
                />
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl>
                <TextField
                  required
                  fullWidth
                  id="post_count"
                  label="게시글 수"
                  value={postCount ?? ""}
                  disabled
                />
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl>
                <TextField
                  required
                  fullWidth
                  id="followers_count"
                  label="팔로워 수"
                  value={followersCount ?? ""}
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={7}>
            <Grid item xs={11}>
              <TextField
                fullWidth
                id="reject_reason"
                label="거절 사유"
                value={rejectReason ?? ""}
                onChange={(e) => setRejectReason(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Button
                fullWidth
                variant={"contained"}
                color={"success"}
                onClick={() => onClickApproveOrReject(true)}
              >승인하기</Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                fullWidth
                variant={"contained"}
                color={"error"}
                onClick={() => onClickApproveOrReject(false, rejectReason)}
              >거절하기</Button>
            </Grid>

            <Grid item xs={2}>
              <Button
                fullWidth
                variant={"contained"}
                color={"info"}
                onClick={onClose}
              >취소</Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}
