export const pmojiData = {
  current_page: 1,
  total_pages: 1,
  total: 0,
  data: {
    pmojis: [],
    // totalPmoji: 0
  }
};

export const pmojiType = [
  {
    value: true,
    name: 'skin',
    label: '스킨'
  },
  {
    value: true,
    name: 'hair',
    label: '헤어'
  },
  {
    value: true,
    name: 'fashion',
    label: '패션'
  },
  {
    value: true,
    name: 'characterSkin',
    label: '프모지'
  },
];

export const pmojiTypeOptions = [
  {
    value: 'skin',
    label: '스킨'
  },
  {
    value: 'hair',
    label: '헤어'
  },
  {
    value: 'fashion',
    label: '패션'
  },
  {
    value: 'characterSkin',
    label: '프모지'
  }
];

export const pmojiHead = [
  { name: '번호' },
  { name: '프모지 파일명' },
  { name: '종류' },
  { name: '프모지 이미지' },
  { name: '파일 관리' }
];
