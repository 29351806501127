import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';

interface AdminDatePickerProps {
  label: string;
  required?: boolean;
  value?: Dayjs | null;
  // @ts-ignore
  onChange?: (value) => void;
}

export default function AdminDatePicker({
  label,
  required,
  value,
  onChange,
}: AdminDatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          label={label}
          sx={{
            width: '100%',
            height: '100%',
          }}
          slotProps={{
            textField: {
              required: !!required,
            },
          }}
          format={'YYYY-MM-DD'}
          value={value}
          onChange={onChange}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}