import api, { CoreResponse } from './api';

interface FetchAllSugarsResponse extends CoreResponse {
  users: UserSugars[];
}
export interface UserSugars {
  userId: number;
  uniqueId: string;
  alias: string;
  phone: string;
  currentSugar: number;
  accumulatedSugar: number;
}
export const FetchAllSugars = async (): Promise<UserSugars[]> => {
  try {
    const { data } = await api.get<FetchAllSugarsResponse>('/api/admin/v2/users/all/sugars');

    return data.users;
  } catch {
    return [];
  }
}

export const PatchSugar = async (userId: number, sugar: number, type: string) => {
  try {
    await api.put('/api/admin/v2/users/all/sugars', {
      userId,
      sugar,
      type
    });

    return true;
  } catch {
    return false;
  }
}

const getSugarList = (params: any) => {
  return api.get(
    `/api/v1/sugars?page=${params.page}&limit=25&order=DESC&${params.type}=${params.keyword}`
  );
};

const getSugarDetail = (id: number) => {
  return api.get(`/api/v1/sugars/${id}`);
};

const sugarUpdate = (params: any) => {
  return api.put(`/api/v1/sugars/update`, {
    userId: params.userId,
    sugar: params.sugar
  });
};

const SugarService = {
  getSugarList,
  getSugarDetail,
  sugarUpdate
};
export default SugarService;
