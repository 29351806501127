import {
  DeletedPmoji,
  EditPmoji,
  EditPmojiDialogProps,
  EditPmojiForm
} from 'types/pmoji.interface';
import '../styles.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { DialogResultProps, DialogTitleProps } from 'types/dialog.interface';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import DialogConfirmClose from 'dialog/DialogConfirmClose';
import { pmojiTypeOptions } from 'types/entities/pmoji';
import DialogConfirm from 'dialog/DialogConfirm';
import Image from 'elements/Image';
import { ImageFile } from 'types/image.interface';
import { OptionDefault } from 'types/option.interface';
import PmojiService from 'services/pmoji.service';
import DialogError from 'dialog/DialogError';
import { dialogMessage } from 'types/entities/dialog';
import DialogSuccess from 'dialog/DialogSuccess';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const IMG_SUFFIX = '?' + Date.now();

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const EditPmojiDialog = ({
  open,
  data,
  handleClose,
  handleRefresh
}: EditPmojiDialogProps) => {
  // const [error, setError] = useState<boolean>(true);
  const [openDialogX, setOpenDialogX] = useState<boolean>(false);
  const [openDialogDelete, setOpenDialogDelete] = useState<boolean>(false);
  const [openDialogErr, setOpenDialogErr] =
    useState<DialogResultProps>(dialogMessage);
  const [openDialogSuccess, setOpenDialogSuccess] =
    useState<DialogResultProps>(dialogMessage);
  const [image, setImage] = useState<ImageFile>({
    file: null,
    fileName: null,
    url: data?.url
  });

  useEffect(() => {
    setImage({ file: null, fileName: null, url: data?.url });
    reset({
      type: data?.type ?? '',
      fileName: ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const validationSchema = Yup.object().shape({
    type: Yup.string(),
    fileName: Yup.string().required()
  });

  const defaultValues = {
    type: '',
    fileName: ''
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues
  };
  const { handleSubmit, formState, control, reset, setError, trigger } =
    useForm<EditPmojiForm>({ mode: 'onChange', ...formOptions });

  const { errors } = formState;

  const { fileName } = errors;

  const handleSuccess = () => {
    setOpenDialogSuccess({ open: false, message: '' });
    handleClose();
  };

  const checkFileName = async (fileName: string) => {
    const res = await PmojiService.checkPmojiName(
      data?.id ?? 0,
      data?.type ?? '',
      fileName
    );
    if (res?.data?.meta?.code === 200) {
      const body: EditPmoji = {
        id: data?.id ?? 0,
        fileName: fileName ?? '',
        typePmoji: data?.type ?? ''
      };
      if (image.file) {
        uploadImage(body);
      } else {
        updatePmoji(body);
      }
    } else {
      const message = res?.data?.meta?.messages[0]?.message ?? 'Error';
      setOpenDialogErr({ open: true, message });
      setError('fileName', { type: 'exist', message });
    }
  };

  const onSubmit: SubmitHandler<EditPmojiForm> = (form) => {
    if (formState.isDirty && form?.fileName) {
      const pmojiList: EditPmoji[] = JSON.parse(
        localStorage.getItem('pmojiList') ?? '[]'
      );
      if (pmojiList && pmojiList.length) {
        const index = pmojiList.findIndex(
          (item: EditPmoji) => item?.fileName === form?.fileName
        );
        if (index !== -1) {
          const message = '이미 있는 파일명이예요';
          setOpenDialogErr({ open: true, message });
          setError('fileName', { type: 'exist', message });
        } else {
          checkFileName(form.fileName);
        }
      } else {
        checkFileName(form.fileName);
      }
    } else {
      const body: EditPmoji = {
        id: data?.id ?? 0,
        typePmoji: data?.type ?? ''
      };
      uploadImage(body);
    }
  };

  const deletePmoji = async () => {
    const deletedIds = JSON.parse(localStorage.getItem('deletedIds') ?? '[]');
    deletedIds.push({
      id: data?.id,
      type: data?.type ?? ''
    });
    const newDeletedIds: DeletedPmoji[] = deletedIds;
    
    localStorage.setItem('deletedIds', JSON.stringify(newDeletedIds));
    const pmojiList: EditPmoji[] = JSON.parse(
      localStorage.getItem('pmojiList') ?? '[]'
    );
    if (pmojiList && pmojiList.length) {
      const index = pmojiList.findIndex(
        (item: EditPmoji) =>
          item?.id === data?.id && item?.typePmoji === data?.type
      );
      if (index !== -1) {
        pmojiList.splice(index, 1);
        localStorage.setItem('pmojiList', JSON.stringify(pmojiList));
      }
    }
    handleRefresh();
    setOpenDialogDelete(false);
    handleClose();
  };

  const updatePmoji = async (body: EditPmoji) => {
    const pmojiList: EditPmoji[] = JSON.parse(
      localStorage.getItem('pmojiList') ?? '[]'
    );
    if (pmojiList && pmojiList.length) {
      const index = pmojiList.findIndex(
        (item: EditPmoji) =>
          item?.id === data?.id && item?.typePmoji === data?.type
      );
      if (index !== -1) {
        pmojiList[index] = { ...pmojiList[index], ...body };
      } else {
        pmojiList.push(body);
      }
    } else {
      pmojiList.push(body);
    }
    localStorage.setItem('pmojiList', JSON.stringify(pmojiList));
    handleRefresh();
    // handleClose();
    setOpenDialogSuccess({ open: true, message: '수정되었습니다.' });
  };

  const uploadImage = async (bodyPmoji: EditPmoji) => {
    const body = new FormData();
    body.append('file', image.file ? image.file : '');
    const response: any = await PmojiService.uploadImage(body);
    if (response) {
      bodyPmoji.imageId = response.data.data.id ?? null;
      bodyPmoji.url = response.data.data.url ?? null;
      updatePmoji(bodyPmoji);
    }
  };

  const handleCloseDialog = (event: DialogTitleProps, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  const getImageUrl = (file: File) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        // convert image file to base64 string
        const fileName = file?.name?.substring(0, file?.name?.lastIndexOf('.'));
        
        setImage({
          file,
          fileName: fileName || file?.name,
          url: reader.result ? reader.result + '' : null
        });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const previewImageUpload = (e: any) => {
    const file = e.target.files[0];
    getImageUrl(file);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseDialog}
        open={open}
        className="edit-pmoji-dialog"
      >
        <BootstrapDialogTitle
          id="edit-pmoji-dialog"
          onClose={() =>
            formState.isDirty || image.file
              ? setOpenDialogX(true)
              : handleClose()
          }
        >
          프모지 수정하기
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form id="edit-pmoji-form" className="edit-pmoji-form">
            <div className="info-item">
              <p className="label">생성되는 번호</p>
              <p className="value">{data?.pmojiNumber}</p>
            </div>
            <div className="checkbox-group-container">
              <p className="label">종류</p>
              <Box className="checkbox-group">
                <FormControl
                  sx={{ m: 3 }}
                  component="fieldset"
                  variant="standard"
                >
                  <RadioGroup name="type" value={data?.type ?? ''}>
                    {pmojiTypeOptions.map(
                      (item: OptionDefault, index: number) => (
                        <FormControlLabel
                          key={index}
                          value={item.value}
                          control={
                            <Radio
                              sx={{
                                color: '#FF6151',
                                '&.Mui-checked': {
                                  color: '#FF6151'
                                }
                              }}
                              checkedIcon={<CheckBoxIcon />}
                              icon={<CheckBoxOutlineBlankIcon />}
                              disabled
                            />
                          }
                          label={item.label}
                        />
                      )
                    )}
                  </RadioGroup>
                </FormControl>
              </Box>
            </div>
            <div className="pmoji-flex-col">
              <p className="label">프모지 파일명</p>
              <Controller
                name="fileName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="fileName"
                    className="value"
                    onFocus={() => trigger('fileName')}
                    onChange={onChange}
                    value={value}
                    type="text"
                    error={!!fileName}
                    // placeholder="프모지 파일명"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                )}
              />
            </div>
            <div className="pmoji-flex-col">
              <p className="label">프모지 업로드</p>
              <div className="upload-pmoji">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  type="file"
                  onChange={previewImageUpload}
                  onClick={(e: any) => (e.target.value = null)}
                />
                <label htmlFor="raised-button-file">
                  <div className="input-file">
                    {image?.fileName ? (
                      <p className="file-name">{image?.fileName}</p>
                    ) : (
                      <p className="input-filename">
                        {data?.fileName}
                      </p>
                    )}
                  </div>
                  <Button component="span" className="button-upload">
                    업로드
                  </Button>
                </label>
              </div>
            </div>
            <div className="preview-upload-img">
              {image.url && (
                <Image
                  src={image.url + (image.file ? '' : IMG_SUFFIX)}
                  alt="Pmoji"
                />
              )}
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className="delete-button"
            onClick={() => setOpenDialogDelete(true)}
          >
            삭제하기
          </Button>
          <Button
            form="edit-pmoji-form"
            className="save-button"
            autoFocus
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={!!fileName || (!image.file && !formState.isDirty)}
          >
            수정하기
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <DialogConfirmClose
        open={openDialogX}
        handleOk={handleClose}
        handleClose={() => setOpenDialogX(false)}
      />
      <DialogConfirm
        title={`프모지 ${data?.fileName}을 삭제하겠습니까?`}
        btnText="확인"
        open={openDialogDelete}
        handleOk={deletePmoji}
        handleClose={() => setOpenDialogDelete(false)}
      />
      <DialogError
        message={openDialogErr.message}
        open={openDialogErr.open}
        handleClose={() => setOpenDialogErr({ open: false, message: '' })}
      />
      <DialogSuccess
        message={openDialogSuccess.message}
        open={openDialogSuccess.open}
        handleClose={handleSuccess}
      />
    </>
  );
};

export default EditPmojiDialog;
