import Loading from 'components/Loading';
import React, { useEffect } from "react";
import Routes from './routes';
import TokenService from "services/token.service";
import { useAppDispatch } from 'app/hooks';
import { getUserInfo } from 'pages/User/userThunk';

const App = () => {
  const dispatch = useAppDispatch();
  // Get userInfo
  useEffect(  () => {
    if (TokenService.getToken()) {
      dispatch(getUserInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="App">
      <Routes />
      <Loading />
    </div>
  );
}

export default App;
