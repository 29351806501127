import api, { CoreResponse } from '../api';

export interface PopupStorePostStatistics {
  [yearAndMonth: string]: {
    postCount: number;
    uniqueUserCount: number;
  }
}

interface FetchPopupStoreStatisticsResponse extends CoreResponse {
  postCountByMonth: PopupStorePostStatistics;
}

export const FetchPopupStorePostStatistics = async () => {
  try {
    const { data } = await api.get<FetchPopupStoreStatisticsResponse>('/api/admin/v2/popup-stores/statistics/posts');

    return data.postCountByMonth;
  } catch {
    return {};
  }
}