import { MemberParams } from 'types/member.interface';
import api from './api';

const getMemberList = (params: MemberParams) => {
  return api.get(
    `/api/v1/user?page=${params.page}&limit=25&order=ASC&keyword=${params.keyword}&type=${params.type}&isMarketing=${params.isMarketing}&orderField=id`
  );
};

const getMemberByID = (id: number) => {
  return api.get(`/api/v1/user/info/${id}`);
};

const updateMember = ({
  userId,
  comment,
  status
}: {
  userId: number | undefined;
  comment: string;
  status: string;
}) => {
  return api.put(`/api/v1/user/${userId}`, { comment, status });
};

const MemberService = {
  getMemberList,
  updateMember,
  getMemberByID
};

export default MemberService;
