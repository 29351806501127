import moment from 'moment';

export const dateFormat = (
  value: string | null | undefined,
  format: string = 'YY.MM.DD'
) => {
  if (typeof value !== 'string') {
    return value;
  }
  return moment(value).format(format);
};

export const pmojiTypeFormat = (type: string) => {
  switch (type) {
    case 'skin':
      return '스킨';
    case 'hair':
      return '헤어';
    case 'fashion':
      return '패션';
    case 'characterSkin':
      return '프모지';
    default:
      return;
  }
};
