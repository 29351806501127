import { create } from 'zustand';
import { Dayjs } from 'dayjs';

interface temporaryPopupStore {
  name: string;
  address: string;
  latitude: string;
  longitude: string;
  descriptionTitle: string;
  description: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  officialLink: string;
  isPreReservation: boolean;
  isTopBeforeStarted: boolean;
  reservationLink: string;
  instagramLink: string;
  isAssociated: boolean;
  isFreeEntrance: boolean;
  isParkingAvailable: boolean;
  hasEvent: boolean;
  eventTitle: string;
  events: string;
  logoImage: string;
  thumbnailImages: string[];
}

const useTemporaryPopupStores = create<{
  temporaryPopupStore: temporaryPopupStore;
  setTemporaryPopupStore: (popupStore: temporaryPopupStore) => void;
}>((set) => ({
  temporaryPopupStore: {
    name: '',
    address: '',
    latitude: '',
    longitude: '',
    descriptionTitle: '',
    description: '',
    startDate: null,
    endDate: null,
    officialLink: '',
    isPreReservation: false,
    isTopBeforeStarted: false,
    reservationLink: '',
    instagramLink: '',
    isAssociated: false,
    isFreeEntrance: false,
    isParkingAvailable: false,
    hasEvent: false,
    eventTitle: '',
    events: '',
    logoImage: '',
    thumbnailImages: [],
  },
  setTemporaryPopupStore: (popupStore) => set({
    temporaryPopupStore: popupStore,
  }),
}));

export default useTemporaryPopupStores;
