import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Grid from "@mui/material/Grid";
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';

import DashboardCard from './DashboardCard';
import { FetchAllUsers, PrezemUser } from '../../services/users.service';
import { isActiveUser } from '../../constants/users.constants';
import { ApplicationPrendy, FetchAllApplicationPrendies } from '../../services/prendies.service';
import { PrendyApplicationStatus } from '../../constants/prendy.constants';
import { FetchPopupStoreRankings, PopupStoreRanking } from '../../services/popup-stores.service';
import {
  FetchPopupStorePostStatistics,
  PopupStorePostStatistics
} from '../../services/popupStores/popup-store-post-statistics';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { FetchAccumulatedSugars } from '../../services/sugars/accumulated-sugars';
import { FetchActiveSugars } from '../../services/sugars/active-sugars';

dayjs.locale('ko');

function useDashboard() {
  const [allUsers, setAllUsers] = useState<PrezemUser[]>([]);
  const [activeUsers, setActiveUsers] = useState<PrezemUser[]>([]);
  const [nowDayjs, setNowDayjs] = useState<dayjs.Dayjs>(dayjs());
  const [thisWeekendStart, setThisWeekendStart] = useState<string>("");
  const [applicationPrendyIsLoaded, setApplicationPrendyIsLoaded] = useState<boolean>(false);
  const [applicationPrendies, setApplicationPrendies] = useState<ApplicationPrendy[]>([]);
  const [popupStoreRankings, setPopupStoreRankings] = useState<PopupStoreRanking[]>([]);
  const [popupStorePostStatistics, setPopupStorePostStatistics] = useState<PopupStorePostStatistics>({});
  const [accumulatedSugars, setAccumulatedSugars] = useState<number>(-1);
  const [activeSugars, setActiveSugars] = useState<number>(-1);

  useEffect(() => {
    const now = dayjs();

    async function fetchAllUsers() {
      const users: PrezemUser[] = await FetchAllUsers();
      setAllUsers(users);
    }

    async function fetchAllPrendies() {
      const applicationPrendies: ApplicationPrendy[] = await FetchAllApplicationPrendies();
      setApplicationPrendies(applicationPrendies.filter((applicationPrendy: ApplicationPrendy) => applicationPrendy.status === PrendyApplicationStatus.APPLICATION));
      setApplicationPrendyIsLoaded(true);
    }

    async function fetchPopupStoreRankings() {
      const popupStoreRankings: PopupStoreRanking[] = await FetchPopupStoreRankings();
      setPopupStoreRankings(popupStoreRankings);
    }

    async function fetchPopupStorePostStatistics() {
      const postStatistics: PopupStorePostStatistics = await FetchPopupStorePostStatistics();
      setPopupStorePostStatistics(postStatistics);
    }

    async function fetchAccumulatedSugars() {
      const accumulatedSugars: number = await FetchAccumulatedSugars();
      setAccumulatedSugars(accumulatedSugars);
    }

    async function fetchActiveSugars() {
      const activeSugars: number = await FetchActiveSugars();
      setActiveSugars(activeSugars);
    }

    fetchAllUsers();
    fetchAllPrendies();
    fetchPopupStoreRankings();
    fetchPopupStorePostStatistics();
    fetchAccumulatedSugars();
    fetchActiveSugars();

    const thisWeekend = now.day(0).format('YYYY-MM-DD 00:00:00');
    setNowDayjs(now);
    setThisWeekendStart(thisWeekend);
  }, []);

  useEffect(() => {
    if (allUsers.length === 0) {
      return;
    }

    const activeUsers: PrezemUser[] = allUsers.filter((user: PrezemUser) => isActiveUser(user.status));
    setActiveUsers(activeUsers);
  }, [allUsers]);

  return {
    models: {
      allUsers,
      activeUsers,
      nowDayjs,
      thisWeekendStart,
      applicationPrendies,
      applicationPrendyIsLoaded,
      popupStoreRankings,
      popupStorePostStatistics,
      accumulatedSugars,
      activeSugars,
    },
    operations: {},
  }
}

export default function Dashboard() {
  const { models } = useDashboard();

  return (
    <div className="main-content">
      <Grid container>
        <Typography variant="h6">회원 통계</Typography>
      </Grid>
      <Grid container
            spacing={1}
            mb={2}>
        <Grid item
              xs={6}
              sm={4}
              md={2}>
          <DashboardCard title={"총 회원수"}
                         mainValue={models.activeUsers.length.toLocaleString('en').toString()}
                         description={"탈퇴회원은 집계 되지 않습니다."}
                         isLoaded={models.allUsers.length > 0} />
        </Grid>

        <Grid item
              xs={6}
              sm={4}
              md={2}>
          <DashboardCard title={"금주 회원가입 수"}
                         mainValue={models.activeUsers.filter((user: PrezemUser) => user.created_at >= models.thisWeekendStart).length.toLocaleString('en').toString()}
                         description={"탈퇴회원은 집계 되지 않으며, 일요일을 주의 시작으로 봅니다."}
                         isLoaded={models.allUsers.length > 0} />
        </Grid>

        <Grid item
              xs={6}
              sm={4}
              md={2}>
          <DashboardCard title={"어제 회원가입 수"}
                         mainValue={models.activeUsers.filter((user: PrezemUser) => user.created_at >= models.nowDayjs.subtract(1, 'day').format('YYYY-MM-DD 00:00:00') && user.created_at < models.nowDayjs.format("YYYY-MM-DD 00:00:00")).length.toLocaleString('en').toString()}
                         description={"탈퇴회원은 집계 되지 않습니다."}
                         isLoaded={models.allUsers.length > 0} />
        </Grid>

        <Grid item
              xs={6}
              sm={4}
              md={2}>
          <DashboardCard title={"오늘 회원가입 수"}
                         mainValue={models.activeUsers.filter((user: PrezemUser) => user.created_at >= models.nowDayjs.format('YYYY-MM-DD 00:00:00')).length.toLocaleString('en').toString()}
                         description={"탈퇴회원은 집계 되지 않습니다."}
                         isLoaded={models.allUsers.length > 0} />
        </Grid>

        <Grid item
              xs={6}
              sm={4}
              md={2}>
          <DashboardCard title={"처리되지 않은 Prendy 신청 수"}
                         mainValue={models.applicationPrendies.length.toString()}
                         description={""}
                         link={models.applicationPrendies.length > 0 ? "/prendies" : undefined}
                         isLoaded={models.applicationPrendyIsLoaded} />
        </Grid>

        <Grid item
              xs={6}
              sm={4}
              md={2}>
          <DashboardCard title={"누적 지급 설탕 수"}
                         mainValue={models.accumulatedSugars.toLocaleString('en')}
                         description={""}
                         isLoaded={models.accumulatedSugars !== -1} />
        </Grid>

        <Grid item
              xs={6}
              sm={4}
              md={2}>
          <DashboardCard title={"유통 설탕 수"}
                         mainValue={models.activeSugars.toLocaleString('en')}
                         description={"유저들이 현재 보유한 설탕 총합계"}
                         isLoaded={models.activeSugars !== -1} />
        </Grid>
      </Grid>

      <Divider>
        <Box component="img"
             alt="divider"
             width="10px"
             src={require('assets/icons/prezem-icon.png')}
        />
      </Divider>

      <Grid container>
        <Typography variant="h6">팝업스토어 통계</Typography>
      </Grid>
      <Grid container
            spacing={1}
            mt={1}>
        {Object
          .keys(models.popupStorePostStatistics)
          .sort((a: string, b: string) => dayjs(a).isBefore(dayjs(b)) ? -1 : 1)
          .map((yearAndMonth: string) => {
          const splitYearAndMonth = yearAndMonth.split('-');
          const yearAndMonthTitle = `${splitYearAndMonth[0].slice(2)}년 ${splitYearAndMonth[1]}월`;

          return (
            <Grid item xs={3} key={`post-statistics-${yearAndMonth}`}>
              <DashboardCard title={`${yearAndMonthTitle} 게시글 수/유저 수`}
                             mainValue={`${models.popupStorePostStatistics[yearAndMonth].postCount.toString()} / ${models.popupStorePostStatistics[yearAndMonth].uniqueUserCount.toString()}`}
                             description={`삭제 게시글은 집계되지 않습니다.`}
                             isLoaded={true} />
            </Grid>
          );
        })}
        <Grid item xs={3}>
          <TableContainer component={Paper}>
            <Table aria-label="ranking table">
              <TableHead>
                <TableRow>
                  <TableCell>순위</TableCell>
                  <TableCell>팝업스토어 명</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {models.popupStoreRankings.map((popupStoreRanking: PopupStoreRanking, index: number) => (
                  <TableRow key={`popup-store-ranking-${popupStoreRanking.id}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{popupStoreRanking.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
